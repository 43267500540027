import {
    GET_SEASON_ACTION, GET_SEASON_ALL_PLAYERS_STATS_ACTION,
    GET_SEASON_APPLICANTS_ACTION,
    GET_SEASON_PLAYER_STATS_ACTION,
    GET_SEASON_PLAYERS_ACTION, GET_SEASON_STATS
} from "../constants/actionTypes";
 
const initialState ={};

const seasonReducer = (state = initialState, action) =>{

    switch(action.type){

        case GET_SEASON_ACTION:
            return{
                ...state,
                season: action.payload,
            }

        case GET_SEASON_APPLICANTS_ACTION:
            return{
                ...state,
                applicants: action.payload,
            }

        case GET_SEASON_PLAYERS_ACTION:
            return{
                ...state,
                players: action.payload,
            }

        case GET_SEASON_PLAYER_STATS_ACTION:
            return{
                ...state,
                playerStats: action.payload,
            }

        case GET_SEASON_ALL_PLAYERS_STATS_ACTION:
            return{
                ...state,
                allPlayersStats: action.payload,
            }

        case GET_SEASON_STATS:
            return{
                ...state,
                seasonStats: action.payload,
            }

        default:
            return state
    }
}

export default seasonReducer;
