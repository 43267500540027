import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {
    getSeasonInit,
    applySeasonInit,
    getSeasonPlayerStatsInit,
    getSeasonAllPlayersStatsInit
} from "../services/seasonService";
import ButtonComponent from "../components/button/buttonComponent";
import {getLeagueInit} from "../services/leagueService";
import PredictionFormComponent from "../components/form/predictionFormComponent";

function SeasonPage(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isRulesOpen, setIsRulesOpen] = useState(false);

    useEffect(() => {
        getLeagueInit(location.pathname.split("/")[2], dispatch);
    }, [location]);

    useEffect(() => {
        if (props.league){
            getSeasonInit(props.league.type + "_seasons", location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3], dispatch)
        }
    }, [props.league]);

    const submit = () => {
        setButtonsDisabled(true)
        applySeasonInit(dispatch, structuredClone(props.season), props.user.id).then(_ => setButtonsDisabled(false))
    }

    return (
        <div>
            <div className="season-page-root">
                {
                    props.season && props.user &&
                    <div className="season-page-wrapper">
                        <div className="title-block">
                            <h1>{props.season.name}</h1>

                            {
                                (props.user.is_admin || props.season.commissioners.includes(props.user.id)) &&
                                <Link to={location.pathname + "/panel"} className="text-link">Panel</Link>
                            }
                        </div>

                        {
                            props.season.players.includes(props.user.id) ?

                                <PredictionFormComponent/>

                                :

                                props.season.applicants.includes(props.user.id) ?

                                    <span>Başvurun Değerlendiriliyor.</span>

                                    :

                                    <ButtonComponent type="submit" text="Sezona Katıl" isDisabled={buttonsDisabled} onClick={submit}/>
                        }


                        {
                            props.season &&
                            <div className="rules-container">
                                <div className="toggle-item" onClick={()=>setIsRulesOpen(!isRulesOpen)}>
                                    <span>KURALLAR</span>
                                    <div className={`chevron-icon black ${isRulesOpen ? "open" : ""}`} />
                                </div>

                                {
                                    isRulesOpen &&
                                    <div>
                                        Oyun serilere tahmin yapmak üzerine(örn 4-2 GSW alır gibi.)
                                        Her round öncesi tahminimizi internet sitemiz üzerinden gireceğiz.
                                        En başta konferans şampiyonları, şampiyon ve MVP tahmini de alacağız.
                                        Buna ek olarak her tur en skorer oyuncu tahmini de alacağız.
                                        <br/><br/>

                                        <h4>PUANLAMA</h4>

                                        <br/><h5>UZUN VADELER</h5>
                                        Konferans galipleri: +6 +6
                                        <br/>Şampiyon: +12
                                        <br/>Finaller MVPsi: +10

                                        <hr/>

                                        <h5>PLAY-IN</h5>
                                        7-8 ve 9-10 Galibi: +2
                                        <br/>7-8 kaybedeni vs 9-10 galibi: +3

                                        <hr/>

                                        <h5>İLK TUR</h5>
                                        --Seri Kazananı: +4
                                        <br/>(Tahmini 7. maçta yatan: +2)
                                        <br/>(Azınlık tahmini: +1)
                                        <br/>(Azınlık oranı %25 ve altında ise ise: +1 ek)
                                        <br/>(Azınlık tahmini tek kişiyse: +1 ek daha)
                                        <br/>(Kazananı ve maç sayısını beraber bilme bonusu: +2)

                                        <hr/>

                                        <h5>İKİNCİ TUR</h5>
                                        --Seri Kazananı: +7
                                        <br/>(Tahmini 7. maçta yatan: +3)
                                        <br/>(Azınlık tahmini: +2)
                                        <br/>(Azınlık oranı %25 ve altında ise ise: +2 ek)
                                        <br/>(Azınlık tahmini tek kişiyse: +2 ek daha)
                                        <br/>(Kazananı ve maç sayısını beraber bilme bonusu: +4)

                                        <hr/>

                                        <h5>KONFERANS FİNALİ</h5>
                                        --Seri Kazananı: +12
                                        <br/>(Tahmini 7. maçta yatan: +5)
                                        <br/>(Azınlık tahmini: +4)
                                        <br/>(Azınlık oranı %25 ve altında ise ise: +3 ek)
                                        <br/>(Azınlık tahmini tek kişiyse: +3 ek daha)
                                        <br/>(Kazananı ve maç sayısını beraber bilme bonusu: +6)

                                        <hr/>

                                        <h5>FİNAL</h5>
                                        --Seri Kazananı: +18
                                        <br/>(Tahmini 7. maçta yatan: +7)
                                        <br/>(Azınlıktaki tahmine: +6)
                                        <br/>(Azınlık oranı %25'e karşı ise: +4)
                                        <br/>(Azınlık tahmini tek kişiyse: +4)
                                        <br/>(Kazananı ve maç sayısını beraber bilme bonusu: +9)

                                        <hr/>

                                        <h5>ÖZEL BONUSLAR</h5>
                                        --İlk tur, bir konferanstaki tüm galipleri bilme(4te4): +4
                                        <br/>--İlk tur, tüm galipleri bilme(8de8): +6 ek
                                        <br/>--Bir konferanstaki ikinci tur galiplerini ve konferans finali galibini + ayrıca final galibini bilme(4te4): +4
                                        <br/>--İki konferanstaki ikinci tur galiplerini ve konferans finali galibini + final galibini bilme(7de7): +4 ek
                                        <br/>--İlk turun en skorer oyuncusu(toplam): +4 ( eşitlik halinde sayı ortalamasına bakılır.)
                                        <br/>--İkinci turun en skorer oyuncusu: +4
                                        <br/>--Konferans finallerinin en skorer oyuncusu: +4
                                        <br/>--Finalin en skorer oyuncusu: +4
                                    </div>
                                }
                            </div>
                        }

                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    user: state.userReducer.user,
    league: state.leagueReducer.league,
    allPlayersStats: state.seasonReducer.allPlayersStats,
})

export default connect(mapStateToProps) (SeasonPage);