import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {isTimeNotUp} from "../../../helper";
import ButtonComponent from "../../button/buttonComponent";
import {updateSeasonPlayerStatsInit} from "../../../services/seasonService";
import ConfirmationTxtLbComponent from "../../layout/confirmationTxtLbComponent";
import NbaPlayoffInputItemComponent from "../../input/nbaPlayoff/nbaPlayoffInputItemComponent";
import {nbaPlayoffSeriesInputOptions} from "../../../constants/variables";

function NbaConfFinalsPredictionsFormComponent(props) {
    const dispatch = useDispatch();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [confirmationTxt, setConfirmationTxt] = useState(null);

    const [predictionsLb, setPredictionsLb] = useState(null);
    const [statisticsLb, setStatisticsLb] = useState(null);

    const [east_1_4_5_8_vs_2_3_6_7_prediction, set_east_1_4_5_8_vs_2_3_6_7_prediction] = useState(null);
    const [west_1_4_5_8_vs_2_3_6_7_prediction, set_west_1_4_5_8_vs_2_3_6_7_prediction] = useState(null);
    const [scorer_prediction, set_scorer_prediction] = useState(null);

    const [is_east_1_4_5_8_vs_2_3_6_7_active, set_east_1_4_5_8_vs_2_3_6_7_active] = useState(false);
    const [is_west_1_4_5_8_vs_2_3_6_7_active, set_west_1_4_5_8_vs_2_3_6_7_active] = useState(false);
    const [is_scorer_active, set_scorer_active] = useState(false);

    useEffect(() => {
        if (props.playerStats) {

            if (props.playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7) {
                const value = props.playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7;
                set_east_1_4_5_8_vs_2_3_6_7_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7) {
                const value = props.playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7;
                set_west_1_4_5_8_vs_2_3_6_7_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.conf_finals_predictions.scorer) {
                set_scorer_prediction({
                    value: props.playerStats.conf_finals_predictions.scorer,
                    label: props.playerStats.conf_finals_predictions.scorer,
                })
            }
        }
    }, [props.playerStats]);

    useEffect(() => {
        setStartTimeActives();
    }, [props.season.info]);

    useEffect(() => {
        setStartTimeActives();
    }, []);

    const setStartTimeActives = () => {
        if (props.season.info) {
            props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.start_time && set_east_1_4_5_8_vs_2_3_6_7_active(isTimeNotUp(props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.start_time))
            props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.start_time && set_west_1_4_5_8_vs_2_3_6_7_active(isTimeNotUp(props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.start_time))
            props.season.info.conf_finals.scorer.start_time && set_scorer_active(isTimeNotUp(props.season.info.conf_finals.scorer.start_time))
        }
    }

    const submitPredictions = () => {
        setButtonsDisabled(true)

        const newPlayerStats = structuredClone(props.playerStats);

        newPlayerStats.conf_finals_predictions = {
            east_1_4_5_8_vs_2_3_6_7: east_1_4_5_8_vs_2_3_6_7_prediction ? east_1_4_5_8_vs_2_3_6_7_prediction.value : null,
            west_1_4_5_8_vs_2_3_6_7: west_1_4_5_8_vs_2_3_6_7_prediction ? west_1_4_5_8_vs_2_3_6_7_prediction.value : null,
            scorer: scorer_prediction ? scorer_prediction.value : null,
        };

        updateSeasonPlayerStatsInit(props.season.type, newPlayerStats, dispatch).then(_ => {
            setButtonsDisabled(false);
            setConfirmationTxt("Konferans Finalleri tahminlerin kaydedildi.")
        });
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>

            {
                props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_1_4_5_8_vs_2_3_6_7_active}
                    inputValue={east_1_4_5_8_vs_2_3_6_7_prediction}
                    setInputValue={set_east_1_4_5_8_vs_2_3_6_7_prediction}
                    inputName={"east_1_4_5_8_vs_2_3_6_7"}
                    labelText={`${props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_1} vs. ${props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_2}`}
                    stats={props.seasonStats.statistics.conf_finals.east_1_4_5_8_vs_2_3_6_7}
                    predictions={props.seasonStats.playersPredictions.conf_finals.east_1_4_5_8_vs_2_3_6_7}
                    info={props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_1_4_5_8_vs_2_3_6_7_active}
                    inputValue={west_1_4_5_8_vs_2_3_6_7_prediction}
                    setInputValue={set_west_1_4_5_8_vs_2_3_6_7_prediction}
                    inputName={"west_1_4_5_8_vs_2_3_6_7"}
                    labelText={`${props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_1} vs. ${props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_2}`}
                    stats={props.seasonStats.statistics.conf_finals.west_1_4_5_8_vs_2_3_6_7}
                    predictions={props.seasonStats.playersPredictions.conf_finals.west_1_4_5_8_vs_2_3_6_7}
                    info={props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.conf_finals.scorer.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_scorer_active}
                    inputValue={scorer_prediction}
                    setInputValue={set_scorer_prediction}
                    inputName={"scorer"}
                    labelText={`Konferans Finallerinin En Skorer Oyuncusu`}
                    predictions={props.seasonStats.playersPredictions.conf_finals.scorer}
                    info={props.season.info.conf_finals.scorer}
                    inputOptions={props.season.info.conf_finals.scorer.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.playerStats && (
                    is_east_1_4_5_8_vs_2_3_6_7_active ||
                    is_west_1_4_5_8_vs_2_3_6_7_active ||
                        is_scorer_active
                ) &&

                <ButtonComponent type="submit" text={"Kaydet"} onClick={submitPredictions}
                                 isDisabled={buttonsDisabled}/>
            }

            {
                confirmationTxt &&
                <ConfirmationTxtLbComponent text={confirmationTxt} setConfirmationTxt={setConfirmationTxt}/>
            }

        </form>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    playerStats: state.seasonReducer.playerStats,
    allPlayersStats: state.seasonReducer.allPlayersStats,
    seasonStats: state.seasonReducer.seasonStats,
})

export default connect(mapStateToProps)(NbaConfFinalsPredictionsFormComponent);