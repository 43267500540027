import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, createStore} from "redux";
import combineRed from "./reducers";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import './assets/styles/main.css';

const middleware = [thunk];

const root = ReactDOM.createRoot(document.getElementById('root'));

let store = createStore(combineRed, {}, applyMiddleware(...middleware))

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);