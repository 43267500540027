import * as actionTypes from '../constants/actionTypes';
import {setUser, getUser} from "./apiCalls";

export const getUserAction = (payload) => ({
    type:actionTypes.GET_USER_ACTION,
    payload
})

export const loginInit = async (user, dispatch) => {
    const docSnap = await getUser(user.id);

    if (docSnap.exists()) {
        dispatch(getUserAction(docSnap.data()));
    } else {
        const newUser = {
            id: user.id,
            email: user.email,
            is_registered: false
        }
        setUser(newUser).then(_ => {
            dispatch(getUserAction(newUser));
        }).catch(err => {
            console.log("setUser err:", err)
        })
    }
}

export const registerInit = async (user, dispatch) => {
    setUser(user).then(_ => {
        dispatch(getUserAction(user));
    }).catch(err => {
        console.log("setUser err:", err)
    })
}












