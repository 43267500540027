
export const LANDING_PAGE = "/lig/nba-playoff-fantezi/2024";

export const LOGIN_PAGE = `/giris`;
export const REGISTER_PAGE = `/kayit`;
export const PROFILE_PAGE = `/profil`;
export const LEAGUE_PAGE = "/lig/:link";
export const LEAGUE_PAGE_WITHOUT_PARAM = "/lig";
export const SEASON_PAGE = "/lig/:link/:yil";
export const SEASON_PANEL_PAGE = "/lig/:link/:yil/panel";

export const NOT_FOUND_PAGE = "/404";
