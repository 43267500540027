import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {isTimeNotUp} from "../../../helper";
import ButtonComponent from "../../button/buttonComponent";
import {updateSeasonPlayerStatsInit} from "../../../services/seasonService";
import ConfirmationTxtLbComponent from "../../layout/confirmationTxtLbComponent";
import NbaPlayoffInputItemComponent from "../../input/nbaPlayoff/nbaPlayoffInputItemComponent";

function NbaLongTermPredictionsFormComponent(props) {
    const dispatch = useDispatch();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [confirmationTxt, setConfirmationTxt] = useState(null);
    const [predictionsLb, setPredictionsLb] = useState(null);
    const [statisticsLb, setStatisticsLb] = useState(null);

    const [east_champion_prediction, set_east_champion_prediction] = useState(null);
    const [west_champion_prediction, set_west_champion_prediction] = useState(null);
    const [champion_prediction, set_champion_prediction] = useState(null);
    const [finals_mvp_prediction, set_finals_mvp_prediction] = useState(null);

    const [is_east_champion_active, set_east_champion_active] = useState(false);
    const [is_west_champion_active, set_west_champion_active] = useState(false);
    const [is_champion_active, set_champion_active] = useState(false);
    const [is_finals_mvp_active, set_finals_mvp_active] = useState(false);

    useEffect(() => {
        if (props.playerStats) {

            if (props.playerStats.long_term_predictions.east_champion) {
                set_east_champion_prediction({
                    value: props.playerStats.long_term_predictions.east_champion,
                    label: props.playerStats.long_term_predictions.east_champion,
                })
            }

            if (props.playerStats.long_term_predictions.west_champion) {
                set_west_champion_prediction({
                    value: props.playerStats.long_term_predictions.west_champion,
                    label: props.playerStats.long_term_predictions.west_champion,
                })
            }

            if (props.playerStats.long_term_predictions.champion) {
                set_champion_prediction({
                    value: props.playerStats.long_term_predictions.champion,
                    label: props.playerStats.long_term_predictions.champion,
                })
            }

            if (props.playerStats.long_term_predictions.finals_mvp) {
                set_finals_mvp_prediction({
                    value: props.playerStats.long_term_predictions.finals_mvp,
                    label: props.playerStats.long_term_predictions.finals_mvp,
                })
            }
        }
    }, [props.playerStats]);

    useEffect(() => {
        setStartTimeActives();
    }, [props.season.info]);

    /*useEffect(() => {
        if (props.allPlayersStats && props.allPlayersStats.length){
            let temp_players_stats = structuredClone(props.allPlayersStats);
            let long_term_stats = {
                picked: [],
                not_picked: []
            };
            for (let temp_player_stat of temp_players_stats){
                if (temp_player_stat.long_term_predictions.champion || temp_player_stat.long_term_predictions.finals_predictions ||
                    temp_player_stat.long_term_predictions.east_champion || temp_player_stat.long_term_predictions.west_champion) {
                    long_term_stats.picked.push({
                        player: temp_player_stat.player,
                        long_term_predictions: temp_player_stat.long_term_predictions
                    })
                } else {
                    long_term_stats.not_picked.push({
                        player: temp_player_stat.player,
                        long_term_predictions: temp_player_stat.long_term_predictions
                    })
                }
            }

            console.log(long_term_stats)
        }
    }, [props.allPlayersStats]);*/

    useEffect(() => {
        setStartTimeActives();
    }, []);

    const setStartTimeActives = () => {
        if (props.season.info) {
            props.season.info.long_term.east_champion.start_time && set_east_champion_active(isTimeNotUp(props.season.info.long_term.east_champion.start_time))
            props.season.info.long_term.west_champion.start_time && set_west_champion_active(isTimeNotUp(props.season.info.long_term.west_champion.start_time))
            props.season.info.long_term.champion.start_time && set_champion_active(isTimeNotUp(props.season.info.long_term.champion.start_time))
            props.season.info.long_term.finals_mvp.start_time && set_finals_mvp_active(isTimeNotUp(props.season.info.long_term.finals_mvp.start_time))
        }
    }

    const submitPredictions = () => {
        setButtonsDisabled(true)

        const newPlayerStats = structuredClone(props.playerStats);

        newPlayerStats.long_term_predictions = {
            east_champion: east_champion_prediction ? east_champion_prediction.value : null,
            west_champion: west_champion_prediction ? west_champion_prediction.value : null,
            champion: champion_prediction ? champion_prediction.value : null,
            finals_mvp: finals_mvp_prediction ? finals_mvp_prediction.value : null,
        };

        updateSeasonPlayerStatsInit(props.season.type, newPlayerStats, dispatch).then(_ => {
            setButtonsDisabled(false);
            setConfirmationTxt("Uzun vadeli tahminlerin kaydedildi.")
        });
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>

            {
                props.season.info.long_term.east_champion.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_champion_active}
                    inputValue={east_champion_prediction}
                    setInputValue={set_east_champion_prediction}
                    inputName={"east_champion"}
                    labelText={`Doğu Kazananı`}
                    predictions={props.seasonStats.playersPredictions.long_term.east_champion}
                    info={props.season.info.long_term.east_champion}
                    inputOptions={props.season.info.long_term.east_champion.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.long_term.west_champion.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_champion_active}
                    inputValue={west_champion_prediction}
                    setInputValue={set_west_champion_prediction}
                    inputName={"west_champion"}
                    labelText={`Batı Kazananı`}
                    predictions={props.seasonStats.playersPredictions.long_term.west_champion}
                    info={props.season.info.long_term.west_champion}
                    inputOptions={props.season.info.long_term.west_champion.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.long_term.champion.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_champion_active}
                    inputValue={champion_prediction}
                    setInputValue={set_champion_prediction}
                    inputName={"champion"}
                    labelText={`Şampiyon`}
                    predictions={props.seasonStats.playersPredictions.long_term.champion}
                    info={props.season.info.long_term.champion}
                    inputOptions={props.season.info.long_term.champion.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.long_term.finals_mvp.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_finals_mvp_active}
                    inputValue={finals_mvp_prediction}
                    setInputValue={set_finals_mvp_prediction}
                    inputName={"finals_mvp"}
                    labelText={`Final MVP'si`}
                    predictions={props.seasonStats.playersPredictions.long_term.finals_mvp}
                    info={props.season.info.long_term.finals_mvp}
                    inputOptions={props.season.info.long_term.finals_mvp.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.playerStats && (
                    is_east_champion_active ||
                    is_west_champion_active ||
                    is_champion_active ||
                    is_finals_mvp_active
                ) &&

                <ButtonComponent type="submit" text={"Kaydet"} onClick={submitPredictions}
                                 isDisabled={buttonsDisabled}/>
            }

            {
                confirmationTxt &&
                <ConfirmationTxtLbComponent text={confirmationTxt} setConfirmationTxt={setConfirmationTxt}/>
            }

        </form>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    playerStats: state.seasonReducer.playerStats,
    allPlayersStats: state.seasonReducer.allPlayersStats,
    seasonStats: state.seasonReducer.seasonStats,
})

export default connect(mapStateToProps)(NbaLongTermPredictionsFormComponent);