import {collection, doc, addDoc, getDoc, getDocs, setDoc, updateDoc, where, query, arrayUnion, arrayRemove, documentId} from 'firebase/firestore/lite';
import {db} from "../firebase-config";

export const addIdToDoc = (collection_type, doc_id) => {
    return updateDoc(doc(db, collection_type, doc_id), {"id": doc_id,});
}

export const getUpTo10DocsWithIdArray = (collection_id, id_array) => {
    return getDocs(query(collection(db, collection_id), where(documentId(), 'in', id_array)));
}

export const getUser = (user_id) => {
    return getDoc(doc(db, "users", user_id));
}

export const setUser = (user) => {
    return setDoc(doc(db, "users", user.id), user);
}

export const checkUserMail = (email) => {
    return getDocs(query(collection(db, "users"), where("email", "==", email)));
}

export const getLeague = (link) => {
    return getDocs(query(collection(db, "leagues"), where("link", "==", link)));
}

export const getSeason = (seasons_type, link) => {
    return getDocs(query(collection(db, seasons_type), where("link", "==", link)));
}

export const addApplicantToSeason = (seasons_type, season_id, user_id) => {
    return updateDoc(doc(db, seasons_type, season_id), {applicants: arrayUnion(user_id)});
}

export const removeApplicantFromSeason = (seasons_type, season_id, user_id) => {
    return updateDoc(doc(db, seasons_type, season_id), {applicants: arrayRemove(user_id)});
}

export const addPlayerToSeason = (seasons_type, season_id, user_id) => {
    return updateDoc(doc(db, seasons_type, season_id), {players: arrayUnion(user_id)});
}

export const addSeasonToUser = (season_id, user_id) => {
    return updateDoc(doc(db, "users", user_id), {player_of: arrayUnion(season_id)});
}

export const createSeasonPlayerStats = (collection_type, stat) => {
    return addDoc(collection(db, collection_type), stat);
}

export const getSeasonInfo = (season_type, year) => {
    return getDoc(doc(db, season_type + "_infos", year));
}

export const getSeasonPlayerStats = (season_type, season_id, player_id) => {
    return getDocs(query(collection(db, season_type + "_seasons_player_stats"), where("player_id", "==", player_id), where("season_id", "==", season_id) ));
}

export const updateSeasonPlayerStats = (season_type, season_player_stats) => {
    return setDoc(doc(db, season_type + "_seasons_player_stats", season_player_stats.id), season_player_stats);
}

export const getSeasonAllPlayersStats = (season_type, season_id) => {
    return getDocs(query(collection(db, season_type + "_seasons_player_stats"), where("season_id", "==", season_id)));
}

export const updateSeasonPlayerPayment = (season_type, player_stats_id, payment) => {
    return updateDoc(doc(db, season_type + "_seasons_player_stats", player_stats_id), {"payment": payment,});
}
