import React from "react";
import {connect} from "react-redux";

function ProfilePage(props) {

    return (
        <div>
            <div className="flex justify-center items-center min-h-screen">
                {
                    props.user &&

                    <div>
                        {
                            props.user.name &&
                            <p>name: {props.user.name}</p>
                        }

                        {
                            props.user.email &&
                            <p>e-mail: {props.user.email}</p>
                        }

                        {
                            props.user.phone_number &&
                            <p>tel no: {props.user.phone_number}</p>
                        }

                        {
                            props.user.owner_of &&
                            <p>
                                sahibi olduğu ligler:

                                {
                                    props.user.owner_of.length > 0 ?
                                        props.user.owner_of.map((league, index)=>
                                            <span key={index}>{league} </span>
                                        )

                                        :

                                        <span> yok</span>
                                }
                            </p>
                        }

                        {
                            props.user.commissioner_of &&
                            <p>
                                komişinırı olduğu ligler:

                                {
                                    props.user.commissioner_of.length > 0 ?
                                        props.user.commissioner_of.map((league, index)=>
                                            <span key={index}>{league} </span>
                                        )

                                        :

                                        <span> yok</span>
                                }
                            </p>
                        }

                        {
                            props.user.player_of &&
                            <p>
                                katılımcı olduğu ligler:

                                {
                                    props.user.player_of.length > 0 ?
                                        props.user.player_of.map((league, index)=>
                                            <span key={index}>{league} </span>
                                        )

                                        :

                                        <span> yok</span>
                                }
                            </p>
                        }


                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

export default connect(mapStateToProps) (ProfilePage);