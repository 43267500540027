import React, {useEffect} from "react";
import ButtonComponent from "../button/buttonComponent";

function ConfirmationTxtLbComponent(props) {
    const lbContainer = React.createRef();

    useEffect(() => {
        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        }
    });

    const windowClicked = (e) => {
        if (lbContainer.current && !lbContainer.current.contains(e.target)) {
            props.setConfirmationTxt(null)
        }
    }

    return (
        <div className="lb-root">
            <div className="lb-wrapper bg-shadow">
                <div className="lb-container" ref={lbContainer}>
                    {/*<div className="close-icon" onClick={()=>props.setConfirmationTxt(null)}/>*/}
                    <p className="confirmation-txt">{props.text}</p>
                    <ButtonComponent type="submit" text={"Tamam"} onClick={()=>props.setConfirmationTxt(null)}/>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationTxtLbComponent;