import{combineReducers} from 'redux';
import userReducer from "./userReducer";
import leagueReducer from "./leagueReducer";
import seasonReducer from "./seasonReducer";
import commonReducer from "./commonReducer";

const combineRed = combineReducers({
    commonReducer,
    userReducer,
    leagueReducer,
    seasonReducer,
});

export default combineRed