
export const nbaPlayoffSeriesInputOptions = [
    {value: "result_4_0", label: "4-0"},
    {value: "result_4_1", label: "4-1"},
    {value: "result_4_2", label: "4-2"},
    {value: "result_4_3", label: "4-3"},
    {value: "result_3_4", label: "3-4"},
    {value: "result_2_4", label: "2-4"},
    {value: "result_1_4", label: "1-4"},
    {value: "result_0_4", label: "0-4"},
]