import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {getSeasonAllPlayersStatsInit, getSeasonPlayerStatsInit} from "../../services/seasonService";
import NbaPlayInPredictionsFormComponent from "./nbaPlayoff/nbaPlayInPredictionsFormComponent";
import NbaPlayoffSeasonStatisticsLbComponent from "./nbaPlayoff/nbaPlayoffSeasonStatisticsLbComponent";
import NbaFirstRoundPredictionsFormComponent from "./nbaPlayoff/nbaFirstRoundPredictionsFormComponent";
import NbaLongTermPredictionsFormComponent from "./nbaPlayoff/nbaLongTermPredictionsFormComponent";
import NbaSecondRoundPredictionsFormComponent from "./nbaPlayoff/nbaSecondRoundPredictionsFormComponent";
import NbaConfFinalsPredictionsFormComponent from "./nbaPlayoff/nbaConfFinalsPredictionsFormComponent";
import NbaFinalsPredictionsFormComponent from "./nbaPlayoff/nbaFinalsPredictionsFormComponent";

function PredictionFormComponent(props) {
    const dispatch = useDispatch();
    const [predictionForm, setPredictionForm] = useState("play-in");
    const [isLeaderboardOpen, setLeaderboardOpen] = useState(false);

    useEffect(() => {
        getSeasonPlayerStatsInit(props.season, props.user.id, dispatch);
    }, []);

    useEffect(() => {
        getSeasonAllPlayersStatsInit(props.season, dispatch)

        if (props.season.info.finals.east_vs_west.start_time){
            setPredictionForm("finals")
        } else if(props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.start_time ||
            props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.start_time){
            setPredictionForm("conf-finals")
        } else if(props.season.info.second_round.east_1_8_vs_4_5.start_time ||
            props.season.info.second_round.east_2_7_vs_3_6.start_time ||
            props.season.info.second_round.west_1_8_vs_4_5.start_time ||
            props.season.info.second_round.west_2_7_vs_3_6.start_time){
            setPredictionForm("second-round")
        } else if(props.season.info.first_round.east_1_vs_8.start_time ||
            props.season.info.first_round.east_2_vs_7.start_time ||
            props.season.info.first_round.east_3_vs_6.start_time ||
            props.season.info.first_round.east_4_vs_5.start_time ||
            props.season.info.first_round.west_1_vs_8.start_time ||
            props.season.info.first_round.west_2_vs_7.start_time ||
            props.season.info.first_round.west_3_vs_6.start_time ||
            props.season.info.first_round.west_4_vs_5.start_time){
            setPredictionForm("first-round")
        } else {
            setPredictionForm("play-in")
        }
    }, [props.season]);

    return(
        <div className="predictions-root">
            {
                props.allPlayersStats &&
                <>
                    <div className="predictions-stats-block">
                        <span onClick={() => setLeaderboardOpen(true)}>Puan Tablosu</span>
                    </div>

                    {
                        isLeaderboardOpen &&
                        <NbaPlayoffSeasonStatisticsLbComponent
                            fieldName={`Puan Tablosu`}
                            setNullFunc={setLeaderboardOpen}
                            allPlayersStats={props.allPlayersStats.sort( ( a, b ) => {return a.total_points < b.total_points ? 1 : (a.total_points > b.total_points ? -1 : 0)} )}
                        />
                    }
                </>
            }
            {
                props.season.name && props.playerStats &&
                <>
                    <h2>Tahminlerin</h2>

                    <div className="form-container predictions">

                        <div className="select-btn-block">

                            {
                                (
                                    props.season.info.play_in.east_7_vs_8.start_time ||
                                    props.season.info.play_in.east_9_vs_10.start_time ||
                                    props.season.info.play_in.west_7_vs_8.start_time ||
                                    props.season.info.play_in.west_9_vs_10.start_time
                                ) &&

                                <div className={`select-btn-item white-btn ${predictionForm === "play-in" ? "active" : ""} `} onClick={()=>setPredictionForm("play-in")}>
                                    Play-in
                                </div>
                            }

                            {
                                (
                                    props.season.info.long_term.east_champion.start_time ||
                                    props.season.info.long_term.west_champion.start_time ||
                                    props.season.info.long_term.champion.start_time ||
                                    props.season.info.long_term.finals_mvp.start_time
                                ) &&

                                <div className={`select-btn-item white-btn ${predictionForm === "long-term" ? "active" : ""} `} onClick={()=>setPredictionForm("long-term")}>
                                    Uzun Vadeli
                                </div>
                            }

                            {
                                (
                                    props.season.info.first_round.east_1_vs_8.start_time ||
                                    props.season.info.first_round.east_2_vs_7.start_time ||
                                    props.season.info.first_round.east_3_vs_6.start_time ||
                                    props.season.info.first_round.east_4_vs_5.start_time ||
                                    props.season.info.first_round.west_1_vs_8.start_time ||
                                    props.season.info.first_round.west_2_vs_7.start_time ||
                                    props.season.info.first_round.west_3_vs_6.start_time ||
                                    props.season.info.first_round.west_4_vs_5.start_time
                                ) &&

                                <div className={`select-btn-item white-btn ${predictionForm === "first-round" ? "active" : ""} `} onClick={()=>setPredictionForm("first-round")}>
                                    1. Tur
                                </div>
                            }

                            {
                                (
                                    props.season.info.second_round.east_1_8_vs_4_5.start_time ||
                                    props.season.info.second_round.east_2_7_vs_3_6.start_time ||
                                    props.season.info.second_round.west_1_8_vs_4_5.start_time ||
                                    props.season.info.second_round.west_2_7_vs_3_6.start_time
                                ) &&

                                <div className={`select-btn-item white-btn ${predictionForm === "second-round" ? "active" : ""} `} onClick={()=>setPredictionForm("second-round")}>
                                    2. Tur
                                </div>
                            }

                            {
                                (
                                    props.season.info.conf_finals.east_1_4_5_8_vs_2_3_6_7.start_time ||
                                    props.season.info.conf_finals.west_1_4_5_8_vs_2_3_6_7.start_time
                                ) &&

                                <div className={`select-btn-item white-btn ${predictionForm === "conf-finals" ? "active" : ""} `} onClick={()=>setPredictionForm("conf-finals")}>
                                    Konferans Finalleri
                                </div>
                            }

                            {
                                props.season.info.finals.east_vs_west.start_time &&

                                <div className={`select-btn-item white-btn ${predictionForm === "finals" ? "active" : ""} `} onClick={()=>setPredictionForm("finals")}>
                                    Final
                                </div>
                            }

                        </div>

                        {
                            predictionForm === "play-in" &&
                            <NbaPlayInPredictionsFormComponent/>
                        }

                        {
                            predictionForm === "first-round" &&
                            <NbaFirstRoundPredictionsFormComponent/>
                        }

                        {
                            predictionForm === "second-round" &&
                            <NbaSecondRoundPredictionsFormComponent/>
                        }

                        {
                            predictionForm === "conf-finals" &&
                            <NbaConfFinalsPredictionsFormComponent/>
                        }

                        {
                            predictionForm === "finals" &&
                            <NbaFinalsPredictionsFormComponent/>
                        }

                        {
                            predictionForm === "long-term" &&
                            <NbaLongTermPredictionsFormComponent/>
                        }
                    </div>

                </>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    user: state.userReducer.user,
    playerStats: state.seasonReducer.playerStats,
    allPlayersStats: state.seasonReducer.allPlayersStats,
})

export default connect(mapStateToProps) (PredictionFormComponent);