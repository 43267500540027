import * as actionTypes from '../constants/actionTypes';
import {getLeague} from "./apiCalls";

export const getLeagueAction = (payload) => ({
    type:actionTypes.GET_LEAGUE_ACTION,
    payload
})

export const getLeagueInit = async (link, dispatch) => {
    const querySnapshot = await getLeague(link);

    if (querySnapshot._docs.length > 0){
        querySnapshot.forEach((doc) => {
            dispatch(getLeagueAction(doc.data()));
        });
    } else {
        console.log("league does not exists!")
    }
}












