import * as actionTypes from '../constants/actionTypes';

export const setLocationHistoryAction = (payload) => ({
    type:actionTypes.SET_LOCATION_HISTORY_ACTION,
    payload
})

export const setPrevLocationAction = (payload) => ({
    type:actionTypes.SET_PREV_LOCATION_ACTION,
    payload
})

export const locationActionInit = (dispatch, location, locationHistory) => {
    if(locationHistory.length > 20){
        locationHistory = locationHistory.slice(-10);
    }

    locationHistory.push(location)
    dispatch(setLocationHistoryAction(locationHistory));
    if (locationHistory.length > 1){
        dispatch(setPrevLocationAction(locationHistory[locationHistory.length - 2]));
    }
}












