import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {
    getSeasonInit,
    getSeasonPanelDataInit,
    addPlayerToSeasonInit,
    getSeasonAllPlayersStatsInit
} from "../services/seasonService";
import {NOT_FOUND_PAGE} from "../constants/routePaths";
import ButtonComponent from "../components/button/buttonComponent";
import {getLeagueInit} from "../services/leagueService";
import NbaPlayoffSeasonPanelPlayerItemComponent
    from "../components/form/nbaPlayoff/nbaPlayoffSeasonPanelPlayerItemComponent";

function SeasonPanelPage(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentList, setPaymentList] = useState(null);

    useEffect(() => {
        getLeagueInit(location.pathname.split("/")[2], dispatch);
    }, [location]);

    useEffect(() => {
        if (props.league){
            getSeasonInit(props.league.type + "_seasons", location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3], dispatch)
        }
    }, [props.league]);

    useEffect(() => {
        if (props.season){
            getSeasonAllPlayersStatsInit(props.season, dispatch)
        }
    }, [props.season]);

    useEffect(() => {
        if (props.season && props.user){
            if(props.user.is_admin || props.season.commissioners.includes(props.user.id)){
                const params = {
                    applicant_ids: props.season.applicants,
                    player_ids: props.season.players
                }

                getSeasonPanelDataInit(params, dispatch)
            } else {
                navigate(NOT_FOUND_PAGE)
            }
        }
    }, [props.season]);

    /*useEffect(() => {
        if (props.allPlayersStats && props.allPlayersStats.length > 0){
            const jsonString = JSON.stringify(props.allPlayersStats);
            console.log(jsonString)
        }
    }, [props.allPlayersStats]);

    useEffect(() => {
        if (props.allPlayersStats && props.allPlayersStats.length){
            let paymentListObj = {
                payment_100: {
                    list: [],
                    pool: 0
                },
                payment_200: {
                    list: [],
                    pool: 0
                },
            };

            for (let i = 0; i < props.allPlayersStats.length; i++){
                const payment = props.allPlayersStats[i].payment;
                const objKey = "payment_" + payment;

                paymentListObj[objKey]["list"].push({payment: payment, player: props.allPlayersStats[i].player})

                if (payment === 100){
                    paymentListObj.payment_100.pool += 100;
                } else if (payment === 200){
                    paymentListObj.payment_100.pool += 100;
                    paymentListObj.payment_200.pool += 100;
                }
            }

            setPaymentList(paymentListObj);
        }
    }, [props.allPlayersStats]);

    if (paymentList){
        console.log(paymentList)
    }*/

    const addPlayerToSeason = (player_id) => {
        setButtonsDisabled(true)
        addPlayerToSeasonInit(dispatch, structuredClone(props.season), structuredClone(props.applicants), structuredClone(props.players), player_id, structuredClone(props.user)).then(_ => setButtonsDisabled(false))
    }



    return (
        <div className="season-panel-page-root">
            <div className="season-panel-page-wrapper">
                {
                    props.applicants && props.applicants.length > 0 &&
                    <div>
                        <h2>ADAYLAR</h2>
                        {
                            props.applicants.map((applicant, index) =>
                                <div key={index} className="applicant-item">
                                    <div>
                                        <p><span>kullanıcı adı:</span> {applicant.name}</p>
                                        <p><span>tel no:</span> {applicant.phone_number}</p>
                                        <p><span>email:</span> {applicant.email}</p>
                                    </div>

                                    <ButtonComponent type="submit" text="Onayla" isDisabled={buttonsDisabled} onClick={() => addPlayerToSeason(applicant.id)}/>
                                </div>
                            )
                        }

                        <hr/>
                        <br/>
                    </div>
                }

                {
                    props.allPlayersStats && props.allPlayersStats.length > 0 &&
                    <div>
                        <h2>OYUNCULAR</h2>

                        <div className="players-container">
                            {
                                props.allPlayersStats.map((playerStats, index) =>
                                    <NbaPlayoffSeasonPanelPlayerItemComponent
                                        key={index}
                                        index={index}
                                        name={playerStats.player.name}
                                        player_stats_id={playerStats.id}
                                        email={playerStats.player.email}
                                        phone_number={playerStats.player.phone_number}
                                        season_type={props.season.type}
                                        payment={playerStats.payment}
                                        buttonsDisabled={buttonsDisabled}
                                        setButtonsDisabled={setButtonsDisabled}
                                        allPlayerStats={props.allPlayersStats}
                                    />
                                )
                            }
                        </div>

                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    applicants: state.seasonReducer.applicants,
    players: state.seasonReducer.players,
    user: state.userReducer.user,
    league: state.leagueReducer.league,
    allPlayersStats: state.seasonReducer.allPlayersStats
})

export default connect(mapStateToProps) (SeasonPanelPage);