import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {isTimeNotUp} from "../../../helper";
import ButtonComponent from "../../button/buttonComponent";
import {updateSeasonPlayerStatsInit} from "../../../services/seasonService";
import ConfirmationTxtLbComponent from "../../layout/confirmationTxtLbComponent";
import NbaPlayoffInputItemComponent from "../../input/nbaPlayoff/nbaPlayoffInputItemComponent";

function NbaPlayInPredictionsFormComponent(props) {
    const dispatch = useDispatch();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [confirmationTxt, setConfirmationTxt] = useState(null);

    const [predictionsLb, setPredictionsLb] = useState(null);
    const [statisticsLb, setStatisticsLb] = useState(null);

    const [east_7_vs_8_prediction, set_east_7_vs_8_prediction] = useState(null);
    const [east_9_vs_10_prediction, set_east_9_vs_10_prediction] = useState(null);
    const [east_8_vs_9_prediction, set_east_8_vs_9_prediction] = useState(null);
    const [west_7_vs_8_prediction, set_west_7_vs_8_prediction] = useState(null);
    const [west_9_vs_10_prediction, set_west_9_vs_10_prediction] = useState(null);
    const [west_8_vs_9_prediction, set_west_8_vs_9_prediction] = useState(null);

    const [is_east_7_vs_8_active, set_east_7_vs_8_active] = useState(false);
    const [is_east_9_vs_10_active, set_east_9_vs_10_active] = useState(false);
    const [is_east_8_vs_9_active, set_east_8_vs_9_active] = useState(false);
    const [is_west_7_vs_8_active, set_west_7_vs_8_active] = useState(false);
    const [is_west_9_vs_10_active, set_west_9_vs_10_active] = useState(false);
    const [is_west_8_vs_9_active, set_west_8_vs_9_active] = useState(false);

    useEffect(() => {
        if (props.playerStats) {

            if (props.playerStats.play_in_predictions.east_7_vs_8) {
                set_east_7_vs_8_prediction({
                    value: props.playerStats.play_in_predictions.east_7_vs_8,
                    label: props.season.info.play_in.east_7_vs_8[props.playerStats.play_in_predictions.east_7_vs_8]
                })
            }

            if (props.playerStats.play_in_predictions.east_9_vs_10) {
                set_east_9_vs_10_prediction({
                    value: props.playerStats.play_in_predictions.east_9_vs_10,
                    label: props.season.info.play_in.east_9_vs_10[props.playerStats.play_in_predictions.east_9_vs_10]
                })
            }

            if (props.playerStats.play_in_predictions.west_7_vs_8) {
                set_west_7_vs_8_prediction({
                    value: props.playerStats.play_in_predictions.west_7_vs_8,
                    label: props.season.info.play_in.west_7_vs_8[props.playerStats.play_in_predictions.west_7_vs_8]
                })
            }

            if (props.playerStats.play_in_predictions.west_9_vs_10) {
                set_west_9_vs_10_prediction({
                    value: props.playerStats.play_in_predictions.west_9_vs_10,
                    label: props.season.info.play_in.west_9_vs_10[props.playerStats.play_in_predictions.west_9_vs_10]
                })
            }

            if (props.playerStats.play_in_predictions.east_8_vs_9) {
                set_east_8_vs_9_prediction({
                    value: props.playerStats.play_in_predictions.east_8_vs_9,
                    label: props.season.info.play_in.east_8_vs_9[props.playerStats.play_in_predictions.east_8_vs_9]
                })
            }

            if (props.playerStats.play_in_predictions.west_8_vs_9) {
                set_west_8_vs_9_prediction({
                    value: props.playerStats.play_in_predictions.west_8_vs_9,
                    label: props.season.info.play_in.west_8_vs_9[props.playerStats.play_in_predictions.west_8_vs_9]
                })
            }
        }
    }, [props.playerStats]);

    useEffect(() => {
        setStartTimeActives();
    }, [props.season.info]);

    useEffect(() => {
        setStartTimeActives();
    }, []);

    const setStartTimeActives = () => {
        if (props.season.info) {
            props.season.info.play_in.east_7_vs_8.start_time && set_east_7_vs_8_active(isTimeNotUp(props.season.info.play_in.east_7_vs_8.start_time))
            props.season.info.play_in.east_9_vs_10.start_time && set_east_9_vs_10_active(isTimeNotUp(props.season.info.play_in.east_9_vs_10.start_time));
            props.season.info.play_in.west_7_vs_8.start_time && set_west_7_vs_8_active(isTimeNotUp(props.season.info.play_in.west_7_vs_8.start_time));
            props.season.info.play_in.west_9_vs_10.start_time && set_west_9_vs_10_active(isTimeNotUp(props.season.info.play_in.west_9_vs_10.start_time));
            props.season.info.play_in.east_8_vs_9.start_time && set_east_8_vs_9_active(isTimeNotUp(props.season.info.play_in.east_8_vs_9.start_time));
            props.season.info.play_in.west_8_vs_9.start_time && set_west_8_vs_9_active(isTimeNotUp(props.season.info.play_in.west_8_vs_9.start_time));
        }
    }

    const submitPredictions = () => {
        setButtonsDisabled(true)

        const newPlayerStats = structuredClone(props.playerStats);

        newPlayerStats.play_in_predictions = {
            east_7_vs_8: east_7_vs_8_prediction ? east_7_vs_8_prediction.value : null,
            east_9_vs_10: east_9_vs_10_prediction ? east_9_vs_10_prediction.value : null,
            east_8_vs_9: east_8_vs_9_prediction ? east_8_vs_9_prediction.value : null,
            west_7_vs_8: west_7_vs_8_prediction ? west_7_vs_8_prediction.value : null,
            west_9_vs_10: west_9_vs_10_prediction ? west_9_vs_10_prediction.value : null,
            west_8_vs_9: west_8_vs_9_prediction ? west_8_vs_9_prediction.value : null,
        };

        updateSeasonPlayerStatsInit(props.season.type, newPlayerStats, dispatch).then(_ => {
            setButtonsDisabled(false);
            setConfirmationTxt("Play-in tahminlerin kaydedildi.")
        });
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>


            {
                props.season.info.play_in.east_7_vs_8.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_7_vs_8_active}
                    inputValue={east_7_vs_8_prediction}
                    setInputValue={set_east_7_vs_8_prediction}
                    inputName={"east_7_vs_8"}
                    labelText={`${props.season.info.play_in.east_7_vs_8.team_1} (Doğu 7)
                        vs. ${props.season.info.play_in.east_7_vs_8.team_2} (Doğu 8)`}
                    stats={props.seasonStats.statistics.play_in.east_7_vs_8}
                    predictions={props.seasonStats.playersPredictions.play_in.east_7_vs_8}
                    info={props.season.info.play_in.east_7_vs_8}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.east_7_vs_8.team_1},
                        {value: "team_2", label: props.season.info.play_in.east_7_vs_8.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.play_in.east_9_vs_10.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_9_vs_10_active}
                    inputValue={east_9_vs_10_prediction}
                    setInputValue={set_east_9_vs_10_prediction}
                    inputName={"east_9_vs_10"}
                    labelText={`${props.season.info.play_in.east_9_vs_10.team_1} (Doğu 9)
                        vs. ${props.season.info.play_in.east_9_vs_10.team_2} (Doğu 10)`}
                    stats={props.seasonStats.statistics.play_in.east_9_vs_10}
                    predictions={props.seasonStats.playersPredictions.play_in.east_9_vs_10}
                    info={props.season.info.play_in.east_9_vs_10}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.east_9_vs_10.team_1},
                        {value: "team_2", label: props.season.info.play_in.east_9_vs_10.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.play_in.west_7_vs_8.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_7_vs_8_active}
                    inputValue={west_7_vs_8_prediction}
                    setInputValue={set_west_7_vs_8_prediction}
                    inputName={"west_7_vs_8"}
                    labelText={`${props.season.info.play_in.west_7_vs_8.team_1} (Batı 7)
                        vs. ${props.season.info.play_in.west_7_vs_8.team_2} (Batı 8)`}
                    stats={props.seasonStats.statistics.play_in.west_7_vs_8}
                    predictions={props.seasonStats.playersPredictions.play_in.west_7_vs_8}
                    info={props.season.info.play_in.west_7_vs_8}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.west_7_vs_8.team_1},
                        {value: "team_2", label: props.season.info.play_in.west_7_vs_8.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.play_in.west_9_vs_10.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_9_vs_10_active}
                    inputValue={west_9_vs_10_prediction}
                    setInputValue={set_west_9_vs_10_prediction}
                    inputName={"west_9_vs_10"}
                    labelText={`${props.season.info.play_in.west_9_vs_10.team_1} (Batı 9)
                        vs. ${props.season.info.play_in.west_9_vs_10.team_2} (Batı 10)`}
                    stats={props.seasonStats.statistics.play_in.west_9_vs_10}
                    predictions={props.seasonStats.playersPredictions.play_in.west_9_vs_10}
                    info={props.season.info.play_in.west_9_vs_10}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.west_9_vs_10.team_1},
                        {value: "team_2", label: props.season.info.play_in.west_9_vs_10.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.play_in.east_8_vs_9.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_8_vs_9_active}
                    inputValue={east_8_vs_9_prediction}
                    setInputValue={set_east_8_vs_9_prediction}
                    inputName={"east_8_vs_9"}
                    labelText={`${props.season.info.play_in.east_8_vs_9.team_1} (Doğu 8)
                        vs. ${props.season.info.play_in.east_8_vs_9.team_2} (Doğu 9)`}
                    stats={props.seasonStats.statistics.play_in.east_8_vs_9}
                    predictions={props.seasonStats.playersPredictions.play_in.east_8_vs_9}
                    info={props.season.info.play_in.east_8_vs_9}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.east_8_vs_9.team_1},
                        {value: "team_2", label: props.season.info.play_in.east_8_vs_9.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.play_in.west_8_vs_9.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_8_vs_9_active}
                    inputValue={west_8_vs_9_prediction}
                    setInputValue={set_west_8_vs_9_prediction}
                    inputName={"west_8_vs_9"}
                    labelText={`${props.season.info.play_in.west_8_vs_9.team_1} (Batı 8)
                        vs. ${props.season.info.play_in.west_8_vs_9.team_2} (Batı 9)`}
                    stats={props.seasonStats.statistics.play_in.west_8_vs_9}
                    predictions={props.seasonStats.playersPredictions.play_in.west_8_vs_9}
                    info={props.season.info.play_in.west_8_vs_9}
                    inputOptions={[
                        {value: "team_1", label: props.season.info.play_in.west_8_vs_9.team_1},
                        {value: "team_2", label: props.season.info.play_in.west_8_vs_9.team_2}
                    ]}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.playerStats && (
                    is_east_7_vs_8_active ||
                    is_east_9_vs_10_active ||
                    is_west_7_vs_8_active ||
                    is_west_9_vs_10_active ||
                    is_east_8_vs_9_active ||
                    is_west_8_vs_9_active
                ) &&

                <ButtonComponent type="submit" text={"Kaydet"} onClick={submitPredictions}
                                 isDisabled={buttonsDisabled}/>
            }

            {
                confirmationTxt &&
                <ConfirmationTxtLbComponent text={confirmationTxt} setConfirmationTxt={setConfirmationTxt}/>
            }

        </form>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    playerStats: state.seasonReducer.playerStats,
    allPlayersStats: state.seasonReducer.allPlayersStats,
    seasonStats: state.seasonReducer.seasonStats,
})

export default connect(mapStateToProps)(NbaPlayInPredictionsFormComponent);