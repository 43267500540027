import {useRef, useEffect} from "react";

export const customStyles = {
    control: (base, {isFocused}) => ({
        ...base,
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontSize: "16px",
        fontWeight: 600,
        fontColor: "black",
        border: "1px solid #BBBBBB",
        cursor: 'text',
        borderRadius: 8,
        padding: "0 0 0 14px",
        backgroundColor: "transparent",
        outline: isFocused && "none",
        boxShadow: "unset",
        height: "56px",
        width:"250px",
        '&:hover': {
            borderColor: '#000000',
            boxShadow: "unset",
        },
        '&:active': {
            borderColor: '#000000',
            boxShadow: "unset",
        }
    }),
    container: (base, {isFocused}) => ({
        // ...base,
        margin: "12px 0 0"
    }),
    option: (styles, {isFocused}) => ({
        ...styles,
        cursor: 'pointer',
        backgroundColor: 'white !important',
        color: isFocused ? 'black' : "#AEAEAE",
        // lineHeight: 2,
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontWeight: 600,
        fontSize: 16,
        padding: "16px 0"
    }),
    noOptionsMessage: (base, state) => ({
        ...base,
        color: '#000',
        fontSize: '14px',
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        width: 0
    }),
    input: styles => ({
        ...styles,
        color: '#000',
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontWeight: 600,
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
    }),
    menu: styles => ({
        ...styles,
        marginTop: 1,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12);',
        borderRadius: "0 0 8px 8px",
        padding: "14px 30px",
        top: "48px !important",
        border: "1px solid #000",
        backgroundColor: 'white',
        zIndex: 5
    }),
    list: styles => ({
        ...styles,
        border: "1px solid #000"
    }),
    menuList: styles => ({
        ...styles,
        zIndex: 12,
    }),
    singleValue: styles => ({
        ...styles,
        color: 'black',
        lineHeight: 2
    }),

};

export const isTimeNotUp = (startTime) => {
    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Turkey",
    };

    const dateString = new Intl.DateTimeFormat("en-US", options).format(new Date())

    const year = parseInt(dateString.split("/")[2].split(",")[0].trim());
    const start_year = parseInt(startTime.split("-")[0]);

    if (start_year > year){
        return true;
    } else if (start_year < year){
        return false;
    }

    const month = parseInt(dateString.split("/")[0].trim());
    const start_month = parseInt(startTime.split("-")[1]);

    if (start_month > month){
        return true;
    } else if (start_month < month){
        return false;
    }

    const day = parseInt(dateString.split("/")[1].split(",")[0].trim());
    const start_day = parseInt(startTime.split("-")[2]);

    if (start_day > day){
        return true;
    } else if (start_day < day){
        return false;
    }

    let hour = parseInt(dateString.split("/")[2].split(",")[1].split(":")[0].trim());
    const start_hour = parseInt(startTime.split("-")[3]);

    if (hour === 24){
        hour = 0;
    }

    if (start_hour > hour){
        return true;
    } else if (start_hour < hour){
        return false;
    }

    const minute = parseInt(dateString.split("/")[2].split(",")[1].split(":")[1].trim());
    const start_minute = parseInt(startTime.split("-")[4]);

    return start_minute >= minute;
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    },[value]);
    return ref.current;
}