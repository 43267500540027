import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {getLeagueInit} from "../services/leagueService";

function LeaguePage(props) {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        getLeagueInit(location.pathname.split("/")[2], dispatch);
    }, []);

    return (
        <div>
            <div className="flex justify-center items-center min-h-screen">
                league page
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    league: state.leagueReducer.league
})

export default connect(mapStateToProps) (LeaguePage);