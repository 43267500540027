import React from "react";
import RegisterFormComponent from "../components/form/registerFormComponent";

function RegisterPage() {

    return (
        <div className="login-page-root">
            <div className="login-page-wrapper">
                <RegisterFormComponent/>
            </div>
        </div>
    );
}

export default RegisterPage;