import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
    sendSignInLinkToEmail,
    signInWithEmailLink,
    isSignInWithEmailLink,
    onAuthStateChanged,
    getAuth
} from "firebase/auth";
import {auth} from "../../firebase-config";
import ButtonComponent from "../button/buttonComponent";
import {loginInit} from "../../services/userService";
import InputErrorTextComponent from "../input/inputErrorTextComponent";
import {LOGIN_PAGE} from "../../constants/routePaths";
import LoaderComponent from "../layout/loaderComponent";
import {checkUserMail} from "../../services/apiCalls";

const validateEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function LoginWithMailFormComponent() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const location = useLocation()
    const [email, setEmail] = useState("");
    const [loginState, setLoginState] = useState("loading");
    const [errorText, setErrorText] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let mailFromLink = searchParams.get("email");
            signInWithEmailLink(auth, mailFromLink, window.location.href).then((result) => {
                    const user = {
                        id: result.user.uid,
                        email: mailFromLink
                    }
                    loginInit(user, dispatch).then(res => {
                        setButtonsDisabled(false);
                    }).catch(err => {
                        console.log("loginInit err:", err);
                    })
                })
                .catch((error) => {
                    console.log("error:", error)
                });
        } else {
            setLoginState("inputMail");
        }
    }, []);

    const submit = () => {
        if (!validateEmail(email)){
            setErrorText("E-mail'inizi kontrol ediniz.");
            return;
        }

        setErrorText("");
        setLoginState("loading");

        sendSignInLinkToEmail(auth, email, {
            url: `${window.location.origin}${LOGIN_PAGE}?email=${email}`,
            handleCodeInApp: true,})
            .then(() => {
                setLoginState("mailSent");
            })
            .catch((err) => {
                console.log("login err", err)
                setErrorText("İşlem şu an gerçekleştirilemiyor, lütfen daha sonra tekrar deneyiniz.");
                setLoginState("mailError");
            });
    }

    return(
        <div className="form-container">

            <h2>Üye Girişi</h2>

            {
                loginState === "loading" &&
                <LoaderComponent/>
            }

            {
                loginState === "inputMail" &&
                <form onSubmit={(e)=>e.preventDefault()}>
                    <div className="input-item">
                        <label>E-mail</label>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}
                               onKeyDown={(e)=>{if (e.key === "Enter" && !buttonsDisabled) submit()}}
                        />
                    </div>

                    <ButtonComponent type="submit" text="Giriş" isDisabled={buttonsDisabled} onClick={submit}/>

                    <InputErrorTextComponent errorText={errorText}/>

                    <div id="recaptcha-container"/>
                </form>
            }

            {
                loginState === "mailSent" &&
                <div>
                    <InputErrorTextComponent errorText={"Mail'inize link gönderildi."}/>
                </div>
            }

            {
                loginState === "mailError" &&
                <div>
                    <InputErrorTextComponent errorText={errorText}/>
                    <span className="text-link" onClick={()=>window.location.reload(true)}>Üye Girişi</span>
                </div>
            }
        </div>
    );
}

export default LoginWithMailFormComponent;