
export const GET_USER_ACTION = "GET_USER_ACTION";
export const GET_LEAGUE_ACTION = "GET_LEAGUE_ACTION";
export const GET_SEASON_ACTION = "GET_SEASON_ACTION";
export const GET_SEASON_APPLICANTS_ACTION = "GET_SEASON_APPLICANTS_ACTION";
export const GET_SEASON_PLAYERS_ACTION = "GET_SEASON_PLAYERS_ACTION";
export const SET_LOCATION_HISTORY_ACTION = "SET_LOCATION_HISTORY";
export const SET_PREV_LOCATION_ACTION = "SET_PREV_LOCATION_ACTION";
export const GET_SEASON_PLAYER_STATS_ACTION = "GET_SEASON_PLAYER_STATS_ACTION";
export const GET_SEASON_ALL_PLAYERS_STATS_ACTION = "GET_SEASON_ALL_PLAYERS_STATS_ACTION";
export const GET_SEASON_STATS = "GET_SEASON_STATS";