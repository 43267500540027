import {GET_USER_ACTION} from "../constants/actionTypes";
 
const initialState ={};

const userReducer = (state = initialState, action) =>{

    switch(action.type){

        case GET_USER_ACTION:
            return{
                ...state,
                user: action.payload,
            }

        default:
            return state
    }
}

export default userReducer;
