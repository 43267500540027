import React from "react";

function LoaderComponent() {
    return (
        <div className="lb-root">
            <div className="lb-wrapper">
                <div className="loader">
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoaderComponent;