import React from "react";

function ButtonComponent(props) {
    return (
        <button type="button" className={`${props.type ? (props.type + "-btn") : "btn-item"} ${props.isDisabled ? "disabled" : ""}`} onClick={props.onClick}>
            {props.text}
        </button>
    );
}

export default ButtonComponent;