import React, {useEffect, useState} from "react";
import ButtonComponent from "../../button/buttonComponent";
import {updateSeasonPlayerPayment} from "../../../services/apiCalls";
import ConfirmationTxtLbComponent from "../../layout/confirmationTxtLbComponent";
import {getSeasonAllPlayersStatsAction} from "../../../services/seasonService";
import {useDispatch} from "react-redux";

function NbaPlayoffSeasonPanelPlayerItemComponent(props) {
    const dispatch = useDispatch();

    const [payment, setPayment] = useState(0);
    const [confirmationTxt, setConfirmationTxt] = useState(null);

    useEffect(() => {
        setPayment(props.payment);
    }, [props]);

    const updatePlayerPaymentSubmit = () => {
        if (payment === ""){
            return;
        }

        props.setButtonsDisabled(true)

        updateSeasonPlayerPayment(props.season_type, props.player_stats_id, payment).then(_ => {
            const allPlayerStats = structuredClone(props.allPlayerStats);

            for (let i = 0; i < allPlayerStats.length; i++){
                if (allPlayerStats[i].id === props.player_stats_id){
                    allPlayerStats[i].payment = payment;
                    break;
                }
            }

            dispatch(getSeasonAllPlayersStatsAction(allPlayerStats))
            setConfirmationTxt("ödeme kaydı değiştirildi.")
            props.setButtonsDisabled(false)
        }).catch(err => {
            console.log("err:", err);
            props.setButtonsDisabled(false)
        })
    }

    return(
        <div>
            <div className="player-item">
                <p><span>kullanıcı adı:</span> {props.name}</p>
                <p><span>tel no:</span> {props.phone_number}</p>
                <p><span>email:</span> {props.email}</p>
                <p>
                    <span>ödeme:</span>
                    <span className="input-item">
                        <input type="otp" value={payment} onChange={(e) => {if (e.target.value === "" || /^[0-9\b]+$/.test(e.target.value)) setPayment(parseInt(e.target.value))}}/>
                    </span>

                    <ButtonComponent type="submit" text="kaydet" isDisabled={props.buttonsDisabled} onClick={updatePlayerPaymentSubmit}/>
                </p>
            </div>

            {
                confirmationTxt &&
                <ConfirmationTxtLbComponent text={confirmationTxt} setConfirmationTxt={setConfirmationTxt}/>
            }
        </div>
    );
}

export default NbaPlayoffSeasonPanelPlayerItemComponent;