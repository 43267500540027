import * as actionTypes from '../constants/actionTypes';
import {
    getSeason,
    addApplicantToSeason,
    getUpTo10DocsWithIdArray,
    createSeasonPlayerStats,
    addIdToDoc,
    removeApplicantFromSeason,
    addPlayerToSeason,
    addSeasonToUser,
    getSeasonInfo,
    getSeasonPlayerStats,
    updateSeasonPlayerStats,
    getSeasonAllPlayersStats,
} from "./apiCalls";
import {getUserAction} from "./userService";

export const getSeasonAction = (payload) => ({
    type:actionTypes.GET_SEASON_ACTION,
    payload
})

export const getSeasonApplicantsAction = (payload) => ({
    type:actionTypes.GET_SEASON_APPLICANTS_ACTION,
    payload
})

export const getSeasonPlayersAction = (payload) => ({
    type:actionTypes.GET_SEASON_PLAYERS_ACTION,
    payload
})

export const getSeasonPlayerStatsAction = (payload) => ({
    type:actionTypes.GET_SEASON_PLAYER_STATS_ACTION,
    payload
})

export const getSeasonAllPlayersStatsAction = (payload) => ({
    type:actionTypes.GET_SEASON_ALL_PLAYERS_STATS_ACTION,
    payload
})

export const getSeasonStatsAction = (payload) => ({
    type:actionTypes.GET_SEASON_STATS,
    payload
})





export const getSeasonInit = async (season_type, link, dispatch) => {
    const querySnapshot = await getSeason(season_type, link);

    let season;

    if (querySnapshot._docs.length > 0){
        querySnapshot.forEach((doc) => {
            season = doc.data();
        });
    } else {
        console.log("league does not exists!");
        return;
    }

    const docSnap = await getSeasonInfo(season_type, season.year)

    if (docSnap.exists()){
        season.info = docSnap.data();
    }

    dispatch(getSeasonAction(season));
}

export const getSeasonPanelDataInit = async (params, dispatch) => {
    let applicants = [];
    let players = [];

    if (params.applicant_ids.length > 0){
        if (params.applicant_ids.length <= 10){
            await getUpTo10DocsWithIdArray("users", params.applicant_ids).then(snap => {
                snap.docs.map(d => applicants.push(d.data()))
            })
        } else {

            let arrayOfArrays = [];

            for (let i=0; i< params.applicant_ids.length; i += 10) {
                arrayOfArrays.push(params.applicant_ids.slice(i, i+10));
            }

            for (let applicantArray of arrayOfArrays) {
                await getUpTo10DocsWithIdArray("users", applicantArray).then(snap => {
                    snap.docs.map(d => applicants.push(d.data()))
                })
            }
        }
    }

    if (params.player_ids.length > 0){
        if (params.player_ids.length <= 10){
            await getUpTo10DocsWithIdArray("users", params.player_ids).then(snap => {
                snap.docs.map(d => players.push(d.data()))
            })
        } else {
            let arrayOfArrays = [];

            for (let i=0; i< params.player_ids.length; i += 10) {
                arrayOfArrays.push(params.player_ids.slice(i, i+10));
            }

            for (let playerArray of arrayOfArrays) {
                await getUpTo10DocsWithIdArray("users", playerArray).then(snap => {
                    snap.docs.map(d => players.push(d.data()))
                })
            }
        }
    }

    dispatch(getSeasonApplicantsAction(applicants));
    dispatch(getSeasonPlayersAction(players));
}

export const applySeasonInit = async (dispatch, season, user_id) => {
    await addApplicantToSeason(season.type + "_seasons", season.id, user_id)
    season.applicants.push(user_id);
    dispatch(getSeasonAction(season));
}

export const addPlayerToSeasonInit = async (dispatch, season, applicants, players, player_id, user) => {
    await removeApplicantFromSeason(season.type + "_seasons", season.id, player_id);
    season.applicants.splice(season.applicants.indexOf(player_id), 1);
    let player;
    for (let i = 0; i < applicants.length; i++){
        if (applicants[i].id === player_id){
            player = applicants.splice(i, 1);
            dispatch(getSeasonApplicantsAction(applicants));
            break;
        }
    }
    await addPlayerToSeason(season.type + "_seasons", season.id, player_id);
    season.players.push(player_id);
    dispatch(getSeasonAction(season));
    players.push(player);
    dispatch(getSeasonPlayersAction(players));
    await addSeasonToUser(season.id, player_id);
    if (player_id === user.id){
        user.player_of.push(season.id)
        dispatch(getUserAction(user));
    }

    const stat = {
        player_id: player_id,
        season_id: season.id,
        total_points: 0,
        payment: 0,

        play_in_points: 0,
        play_in_predictions: {
            east_7_vs_8: null,
            east_9_vs_10: null,
            east_8_vs_9: null,
            west_7_vs_8: null,
            west_9_vs_10: null,
            west_8_vs_9: null,
        },

        long_term_points: 0,
        long_term_predictions: {
            east_champion: null,
            west_champion: null,
            champion: null,
            finals_mvp: null,
        },

        first_round_points: 0,
        first_round_predictions: {
            east_1_vs_8: null,
            east_2_vs_7: null,
            east_3_vs_6: null,
            east_4_vs_5: null,
            west_1_vs_8: null,
            west_2_vs_7: null,
            west_3_vs_6: null,
            west_4_vs_5: null,
            scorer: null,
        },

        second_round_points: 0,
        second_round_predictions: {
            east_1_8_vs_4_5: null,
            east_2_7_vs_3_6: null,
            west_1_8_vs_4_5: null,
            west_2_7_vs_3_6: null,
            scorer: null,
        },

        conf_finals_points: 0,
        conf_finals_predictions: {
            east_1_4_5_8_vs_2_3_6_7: null,
            west_1_4_5_8_vs_2_3_6_7: null,
            scorer: null,
        },

        finals_points:0,
        finals_predictions: {
            east_vs_west: null,
            scorer: null,
        },

        bonus_points: 0,
    }

    const collection_type = season.type + "_seasons_player_stats";
    const docRef = await createSeasonPlayerStats(collection_type, stat);
    await addIdToDoc(collection_type, docRef.id);
}

export const getSeasonPlayerStatsInit = async (season, player_id, dispatch) => {
    const querySnapshot = await getSeasonPlayerStats(season.type, season.id, player_id)

    if (querySnapshot._docs.length > 0){
        querySnapshot.forEach((doc) => {
            dispatch(getSeasonPlayerStatsAction(doc.data()));
        });
    } else {
        console.log("league does not exists!");
    }
}

export const updateSeasonPlayerStatsInit = async (season_type, playerStats, dispatch) => {
    await updateSeasonPlayerStats(season_type, playerStats)
    dispatch(getSeasonPlayerStatsAction(playerStats));
}

export const getSeasonAllPlayersStatsInit = async (season, dispatch) => {
    const seasonPlayersStatsQuerySnapshot =  await getSeasonAllPlayersStats(season.type, season.id);
    const seasonAllPlayersStats = [];
    const season_player_ids = [];

    if (seasonPlayersStatsQuerySnapshot._docs.length > 0){
        seasonPlayersStatsQuerySnapshot.forEach((doc) => {
            seasonAllPlayersStats.push(doc.data());
            season_player_ids.push(doc.data().player_id);
        });
    }

    const seasonPlayersDatas = [];

    if (season_player_ids.length > 0){
        if (season_player_ids.length <= 10){
            await getUpTo10DocsWithIdArray("users", season_player_ids).then(snap => {
                snap.docs.map(d => {seasonPlayersDatas.push(d.data())})
            })
        } else {

            let arrayOfArrays = [];

            for (let i=0; i< season_player_ids.length; i += 10) {
                arrayOfArrays.push(season_player_ids.slice(i, i+10));
            }

            for (let playerArray of arrayOfArrays) {
                await getUpTo10DocsWithIdArray("users", playerArray).then(snap => {
                    snap.docs.map(d => seasonPlayersDatas.push(d.data()))
                })
            }
        }
    }

    for (let playerStat of seasonAllPlayersStats){
        for (let playerData of seasonPlayersDatas){
            if (playerData.id === playerStat.player_id){
                playerStat.player = playerData;
            }
        }
    }

    const seasonStats = setSeasonStats(seasonAllPlayersStats, season.info);
    setPlayersPoints(seasonAllPlayersStats, season.info, seasonStats);

    dispatch(getSeasonStatsAction(seasonStats));
    dispatch(getSeasonAllPlayersStatsAction(seasonAllPlayersStats));
}





const setSeasonStats = (allPlayersStats, seasonInfo) => {
    const players_predictions = {
        play_in: {
            east_7_vs_8: [],
            east_9_vs_10: [],
            east_8_vs_9: [],
            west_7_vs_8: [],
            west_9_vs_10: [],
            west_8_vs_9: [],
        },

        long_term: {
            east_champion: [],
            west_champion: [],
            champion: [],
            finals_mvp: [],
        },

        first_round: {
            east_1_vs_8: [],
            east_2_vs_7: [],
            east_3_vs_6: [],
            east_4_vs_5: [],
            west_1_vs_8: [],
            west_2_vs_7: [],
            west_3_vs_6: [],
            west_4_vs_5: [],
            scorer: [],
        },

        second_round: {
            east_1_8_vs_4_5: [],
            east_2_7_vs_3_6: [],
            west_1_8_vs_4_5: [],
            west_2_7_vs_3_6: [],
            scorer: [],
        },

        conf_finals: {
            east_1_4_5_8_vs_2_3_6_7: [],
            west_1_4_5_8_vs_2_3_6_7: [],
            scorer: [],
        },

        finals: {
            east_vs_west: [],
            scorer: [],
        },
    };

    const statisticsObject = {
        play_in: {
            east_7_vs_8: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
            east_9_vs_10: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
            east_8_vs_9: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
            west_7_vs_8: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
            west_9_vs_10: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
            west_8_vs_9: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                }
            },
        },

        long_term: {
            east_champion: [],
            west_champion: [],
            champion: [],
            finals_mvp: [],
        },

        first_round: {
            east_1_vs_8: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            east_2_vs_7: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            east_3_vs_6: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            east_4_vs_5: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_1_vs_8: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_2_vs_7: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_3_vs_6: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_4_vs_5: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            scorer: [],
        },

        second_round: {
            east_1_8_vs_4_5: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            east_2_7_vs_3_6: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_1_8_vs_4_5: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_2_7_vs_3_6: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            scorer: [],
        },

        conf_finals: {
            east_1_4_5_8_vs_2_3_6_7: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            west_1_4_5_8_vs_2_3_6_7: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            scorer: [],
        },

        finals: {
            east_vs_west: {
                team_1: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                team_2: {
                    name: null,
                    amount: 0,
                    percentage: null
                },
                result_4_0: {
                    name: "4-0",
                    amount: 0,
                    percentage: null
                },
                result_4_1: {
                    name: "4-1",
                    amount: 0,
                    percentage: null
                },
                result_4_2: {
                    name: "4-2",
                    amount: 0,
                    percentage: null
                },
                result_4_3: {
                    name: "4-3",
                    amount: 0,
                    percentage: null
                },
                result_3_4: {
                    name: "3-4",
                    amount: 0,
                    percentage: null
                },
                result_2_4: {
                    name: "2-4",
                    amount: 0,
                    percentage: null
                },
                result_1_4: {
                    name: "1-4",
                    amount: 0,
                    percentage: null
                },
                result_0_4: {
                    name: "0-4",
                    amount: 0,
                    percentage: null
                },
            },
            scorer: [],
        },
    }

    for (let playerStats of allPlayersStats) {

        if (playerStats.play_in_predictions) {

            if (playerStats.play_in_predictions.east_7_vs_8) {

                players_predictions.play_in.east_7_vs_8.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.east_7_vs_8[playerStats.play_in_predictions.east_7_vs_8],
                })

                statisticsObject.play_in.east_7_vs_8[playerStats.play_in_predictions.east_7_vs_8].amount++;
            }

            if (playerStats.play_in_predictions.east_9_vs_10) {

                players_predictions.play_in.east_9_vs_10.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.east_9_vs_10[playerStats.play_in_predictions.east_9_vs_10],
                })

                statisticsObject.play_in.east_9_vs_10[playerStats.play_in_predictions.east_9_vs_10].amount++;
            }

            if (playerStats.play_in_predictions.east_8_vs_9) {

                players_predictions.play_in.east_8_vs_9.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.east_8_vs_9[playerStats.play_in_predictions.east_8_vs_9],
                })

                statisticsObject.play_in.east_8_vs_9[playerStats.play_in_predictions.east_8_vs_9].amount++;
            }

            if (playerStats.play_in_predictions.west_7_vs_8) {

                players_predictions.play_in.west_7_vs_8.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.west_7_vs_8[playerStats.play_in_predictions.west_7_vs_8],
                })

                statisticsObject.play_in.west_7_vs_8[playerStats.play_in_predictions.west_7_vs_8].amount++;
            }

            if (playerStats.play_in_predictions.west_9_vs_10) {

                players_predictions.play_in.west_9_vs_10.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.west_9_vs_10[playerStats.play_in_predictions.west_9_vs_10],
                })

                statisticsObject.play_in.west_9_vs_10[playerStats.play_in_predictions.west_9_vs_10].amount++;
            }

            if (playerStats.play_in_predictions.west_8_vs_9) {

                players_predictions.play_in.west_8_vs_9.push({
                    name: playerStats.player.name,
                    prediction: seasonInfo.play_in.west_8_vs_9[playerStats.play_in_predictions.west_8_vs_9],
                })

                statisticsObject.play_in.west_8_vs_9[playerStats.play_in_predictions.west_8_vs_9].amount++;
            }
        }

        if (playerStats.long_term_predictions) {

            if (playerStats.long_term_predictions.east_champion) {

                players_predictions.long_term.east_champion.push({
                    name: playerStats.player.name,
                    prediction: playerStats.long_term_predictions.east_champion,
                })

                let east_champion_prediction = statisticsObject.long_term.east_champion.find(x => x === playerStats.long_term_predictions.east_champion);

                if (!east_champion_prediction){
                    statisticsObject.long_term.east_champion.push({
                        name: playerStats.long_term_predictions.east_champion,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    east_champion_prediction.amount++;
                }
            }

            if (playerStats.long_term_predictions.west_champion) {

                players_predictions.long_term.west_champion.push({
                    name: playerStats.player.name,
                    prediction: playerStats.long_term_predictions.west_champion,
                })

                let west_champion_prediction = statisticsObject.long_term.west_champion.find(x => x === playerStats.long_term_predictions.west_champion);

                if (!west_champion_prediction){
                    statisticsObject.long_term.west_champion.push({
                        name: playerStats.long_term_predictions.west_champion,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    west_champion_prediction.amount++;
                }
            }

            if (playerStats.long_term_predictions.champion) {

                players_predictions.long_term.champion.push({
                    name: playerStats.player.name,
                    prediction: playerStats.long_term_predictions.champion,
                })

                let championPrediction = statisticsObject.long_term.champion.find(x => x === playerStats.long_term_predictions.champion);

                if (!championPrediction){
                    statisticsObject.long_term.champion.push({
                        name: playerStats.long_term_predictions.champion,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    championPrediction.amount++;
                }
            }

            if (playerStats.long_term_predictions.finals_mvp) {

                players_predictions.long_term.finals_mvp.push({
                    name: playerStats.player.name,
                    prediction: playerStats.long_term_predictions.finals_mvp,
                })

                let finals_mvp_prediction = statisticsObject.long_term.finals_mvp.find(x => x === playerStats.long_term_predictions.finals_mvp);

                if (!finals_mvp_prediction){
                    statisticsObject.long_term.finals_mvp.push({
                        name: playerStats.long_term_predictions.finals_mvp,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    finals_mvp_prediction.amount++;
                }
            }
        }

        if (playerStats.first_round_predictions) {

            if (playerStats.first_round_predictions.east_1_vs_8) {
                const value = playerStats.first_round_predictions.east_1_vs_8;
                players_predictions.first_round.east_1_vs_8.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.east_1_vs_8[playerStats.first_round_predictions.east_1_vs_8].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.east_1_vs_8.team_1.amount++
                } else {
                    statisticsObject.first_round.east_1_vs_8.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.east_2_vs_7) {
                const value = playerStats.first_round_predictions.east_2_vs_7;
                players_predictions.first_round.east_2_vs_7.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.east_2_vs_7[playerStats.first_round_predictions.east_2_vs_7].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.east_2_vs_7.team_1.amount++
                } else {
                    statisticsObject.first_round.east_2_vs_7.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.east_3_vs_6) {
                const value = playerStats.first_round_predictions.east_3_vs_6;
                players_predictions.first_round.east_3_vs_6.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.east_3_vs_6[playerStats.first_round_predictions.east_3_vs_6].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.east_3_vs_6.team_1.amount++
                } else {
                    statisticsObject.first_round.east_3_vs_6.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.east_4_vs_5) {
                const value = playerStats.first_round_predictions.east_4_vs_5;
                players_predictions.first_round.east_4_vs_5.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.east_4_vs_5[playerStats.first_round_predictions.east_4_vs_5].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.east_4_vs_5.team_1.amount++
                } else {
                    statisticsObject.first_round.east_4_vs_5.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.west_1_vs_8) {
                const value = playerStats.first_round_predictions.west_1_vs_8;
                players_predictions.first_round.west_1_vs_8.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.west_1_vs_8[playerStats.first_round_predictions.west_1_vs_8].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.west_1_vs_8.team_1.amount++
                } else {
                    statisticsObject.first_round.west_1_vs_8.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.west_2_vs_7) {
                const value = playerStats.first_round_predictions.west_2_vs_7;
                players_predictions.first_round.west_2_vs_7.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.west_2_vs_7[playerStats.first_round_predictions.west_2_vs_7].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.west_2_vs_7.team_1.amount++
                } else {
                    statisticsObject.first_round.west_2_vs_7.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.west_3_vs_6) {
                const value = playerStats.first_round_predictions.west_3_vs_6;
                players_predictions.first_round.west_3_vs_6.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.west_3_vs_6[playerStats.first_round_predictions.west_3_vs_6].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.west_3_vs_6.team_1.amount++
                } else {
                    statisticsObject.first_round.west_3_vs_6.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.west_4_vs_5) {
                const value = playerStats.first_round_predictions.west_4_vs_5;
                players_predictions.first_round.west_4_vs_5.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.first_round.west_4_vs_5[playerStats.first_round_predictions.west_4_vs_5].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.first_round.west_4_vs_5.team_1.amount++
                } else {
                    statisticsObject.first_round.west_4_vs_5.team_2.amount++
                }
            }

            if (playerStats.first_round_predictions.scorer) {

                players_predictions.first_round.scorer.push({
                    name: playerStats.player.name,
                    prediction: playerStats.first_round_predictions.scorer,
                })

                let scorerPrediction = statisticsObject.first_round.scorer.find(x => x === playerStats.first_round_predictions.scorer);

                if (!scorerPrediction){
                    statisticsObject.first_round.scorer.push({
                        name: playerStats.first_round_predictions.scorer,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    scorerPrediction.amount++;
                }
            }
        }

        if (playerStats.second_round_predictions) {

            if (playerStats.second_round_predictions.east_1_8_vs_4_5) {
                const value = playerStats.second_round_predictions.east_1_8_vs_4_5;
                players_predictions.second_round.east_1_8_vs_4_5.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.second_round.east_1_8_vs_4_5[playerStats.second_round_predictions.east_1_8_vs_4_5].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.second_round.east_1_8_vs_4_5.team_1.amount++
                } else {
                    statisticsObject.second_round.east_1_8_vs_4_5.team_2.amount++
                }
            }

            if (playerStats.second_round_predictions.east_2_7_vs_3_6) {
                const value = playerStats.second_round_predictions.east_2_7_vs_3_6;
                players_predictions.second_round.east_2_7_vs_3_6.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.second_round.east_2_7_vs_3_6[playerStats.second_round_predictions.east_2_7_vs_3_6].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.second_round.east_2_7_vs_3_6.team_1.amount++
                } else {
                    statisticsObject.second_round.east_2_7_vs_3_6.team_2.amount++
                }
            }

            if (playerStats.second_round_predictions.west_1_8_vs_4_5) {
                const value = playerStats.second_round_predictions.west_1_8_vs_4_5;
                players_predictions.second_round.west_1_8_vs_4_5.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.second_round.west_1_8_vs_4_5[playerStats.second_round_predictions.west_1_8_vs_4_5].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.second_round.west_1_8_vs_4_5.team_1.amount++
                } else {
                    statisticsObject.second_round.west_1_8_vs_4_5.team_2.amount++
                }
            }

            if (playerStats.second_round_predictions.west_2_7_vs_3_6) {
                const value = playerStats.second_round_predictions.west_2_7_vs_3_6;
                players_predictions.second_round.west_2_7_vs_3_6.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.second_round.west_2_7_vs_3_6[playerStats.second_round_predictions.west_2_7_vs_3_6].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.second_round.west_2_7_vs_3_6.team_1.amount++
                } else {
                    statisticsObject.second_round.west_2_7_vs_3_6.team_2.amount++
                }
            }

            if (playerStats.second_round_predictions.scorer) {

                players_predictions.second_round.scorer.push({
                    name: playerStats.player.name,
                    prediction: playerStats.second_round_predictions.scorer,
                })

                let scorerPrediction = statisticsObject.second_round.scorer.find(x => x === playerStats.second_round_predictions.scorer);

                if (!scorerPrediction){
                    statisticsObject.second_round.scorer.push({
                        name: playerStats.second_round_predictions.scorer,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    scorerPrediction.amount++;
                }
            }
        }

        if (playerStats.conf_finals_predictions) {

            if (playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7) {
                const value = playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7;
                players_predictions.conf_finals.east_1_4_5_8_vs_2_3_6_7.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.conf_finals.east_1_4_5_8_vs_2_3_6_7[playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_1.amount++
                } else {
                    statisticsObject.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_2.amount++
                }
            }


            if (playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7) {
                const value = playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7;
                players_predictions.conf_finals.west_1_4_5_8_vs_2_3_6_7.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.conf_finals.west_1_4_5_8_vs_2_3_6_7[playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_1.amount++
                } else {
                    statisticsObject.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_2.amount++
                }
            }

            if (playerStats.conf_finals_predictions.scorer) {

                players_predictions.conf_finals.scorer.push({
                    name: playerStats.player.name,
                    prediction: playerStats.conf_finals_predictions.scorer,
                })

                let scorerPrediction = statisticsObject.conf_finals.scorer.find(x => x === playerStats.conf_finals_predictions.scorer);

                if (!scorerPrediction){
                    statisticsObject.conf_finals.scorer.push({
                        name: playerStats.conf_finals_predictions.scorer,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    scorerPrediction.amount++;
                }
            }
        }

        if (playerStats.finals_predictions) {

            if (playerStats.finals_predictions.east_vs_west) {
                const value = playerStats.finals_predictions.east_vs_west;
                players_predictions.finals.east_vs_west.push({
                    name: playerStats.player.name,
                    prediction: value.split("_")[1] + "-" + value.split("_")[2],
                })

                statisticsObject.finals.east_vs_west[playerStats.finals_predictions.east_vs_west].amount++;

                if (value.split("_")[1] === "4"){
                    statisticsObject.finals.east_vs_west.team_1.amount++
                } else {
                    statisticsObject.finals.east_vs_west.team_2.amount++
                }
            }

            if (playerStats.finals_predictions.scorer) {

                players_predictions.finals.scorer.push({
                    name: playerStats.player.name,
                    prediction: playerStats.finals_predictions.scorer,
                })

                let scorerPrediction = statisticsObject.finals.scorer.find(x => x === playerStats.finals_predictions.scorer);

                if (!scorerPrediction){
                    statisticsObject.finals.scorer.push({
                        name: playerStats.finals_predictions.scorer,
                        amount: 1,
                        percentage: null
                    })
                } else {
                    scorerPrediction.amount++;
                }
            }
        }
    }

    for (let statObj of Object.keys(statisticsObject.play_in)){
        statisticsObject.play_in[statObj]["team_1"]["name"] = seasonInfo.play_in[statObj]["team_1"];
        statisticsObject.play_in[statObj]["team_2"]["name"] = seasonInfo.play_in[statObj]["team_2"];

        const team_1_amount = statisticsObject.play_in[statObj]["team_1"]["amount"];
        const team_2_amount = statisticsObject.play_in[statObj]["team_2"]["amount"];

        const total = team_1_amount + team_2_amount;

        statisticsObject.play_in[statObj]["team_1"]["percentage"] = (100 * team_1_amount / total).toFixed(2);
        statisticsObject.play_in[statObj]["team_2"]["percentage"] = (100 * team_2_amount / total).toFixed(2);
    }

    const roundArray = ["first_round", "second_round", "conf_finals", "finals"];

    roundArray.forEach(round => {
        for (let statObj of Object.keys(statisticsObject[round])){
            if (statObj !== "scorer"){
                statisticsObject[round][statObj]["team_1"]["name"] = seasonInfo[round][statObj]["team_1"];
                statisticsObject[round][statObj]["team_2"]["name"] = seasonInfo[round][statObj]["team_2"];

                const team_1_amount = statisticsObject[round][statObj]["team_1"]["amount"];
                const team_2_amount = statisticsObject[round][statObj]["team_2"]["amount"];

                const totalAmount = team_1_amount + team_2_amount;

                statisticsObject[round][statObj]["team_1"]["percentage"] = (100 * team_1_amount / totalAmount).toFixed(2);
                statisticsObject[round][statObj]["team_2"]["percentage"] = (100 * team_2_amount / totalAmount).toFixed(2);

                const result_4_0_amount = statisticsObject[round][statObj]["result_4_0"]["amount"];
                const result_4_1_amount = statisticsObject[round][statObj]["result_4_1"]["amount"];
                const result_4_2_amount = statisticsObject[round][statObj]["result_4_2"]["amount"];
                const result_4_3_amount = statisticsObject[round][statObj]["result_4_3"]["amount"];
                const result_3_4_amount = statisticsObject[round][statObj]["result_3_4"]["amount"];
                const result_2_4_amount = statisticsObject[round][statObj]["result_2_4"]["amount"];
                const result_1_4_amount = statisticsObject[round][statObj]["result_1_4"]["amount"];
                const result_0_4_amount = statisticsObject[round][statObj]["result_0_4"]["amount"];

                const totalResult = result_0_4_amount + result_1_4_amount + result_2_4_amount + result_3_4_amount + result_4_3_amount + result_4_2_amount + result_4_2_amount + result_4_1_amount + result_4_0_amount;

                statisticsObject[round][statObj]["result_4_0"]["percentage"] = (100 * result_4_0_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_4_1"]["percentage"] = (100 * result_4_1_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_4_2"]["percentage"] = (100 * result_4_2_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_4_3"]["percentage"] = (100 * result_4_3_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_3_4"]["percentage"] = (100 * result_3_4_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_2_4"]["percentage"] = (100 * result_2_4_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_1_4"]["percentage"] = (100 * result_1_4_amount / totalResult).toFixed(2);
                statisticsObject[round][statObj]["result_0_4"]["percentage"] = (100 * result_0_4_amount / totalResult).toFixed(2);
            }
        }
    })

    return {
        playersPredictions: players_predictions,
        statistics: statisticsObject
    }
}

const setPlayersPoints = (playersStats, seasonInfo, seasonStats) => {

    //play-in points
    const play_in_7_vs_8_result_point = 2;
    const play_in_9_vs_10_result_point = 2;
    const play_in_8_vs_9_result_point = 3;

    //long term points
    const conf_champion_point = 6;
    const champion_point = 12;
    const finals_mvp_point = 10;

    //first round points
    const first_round_winner_point = 4;
    const first_round_seventh_game_lost_point = 2;
    const first_round_minority_point = 1;
    const first_round_below_25_minority_point= 2;
    const first_round_only_minority_point= 3;
    const first_round_result_point= 2;

    //second round points
    const second_round_winner_point = 7;
    const second_round_seventh_game_lost_point = 3;
    const second_round_minority_point = 2;
    const second_round_below_25_minority_point= 4;
    const second_round_only_minority_point= 6;
    const second_round_result_point= 4;

    //conf finals points
    const conf_finals_winner_point = 12;
    const conf_finals_seventh_game_lost_point = 5;
    const conf_finals_minority_point = 4;
    const conf_finals_below_25_minority_point= 7;
    const conf_finals_only_minority_point= 10;
    const conf_finals_result_point= 6;

    //finals points
    const finals_winner_point = 18;
    const finals_seventh_game_lost_point = 7;
    const finals_minority_point = 6;
    const finals_below_25_minority_point= 10;
    const finals_only_minority_point= 14;
    const finals_result_point= 9;

    //bonus points
    const scorer_point = 4;
    const first_round_all_conf_series_winners_point = 4;
    const first_round_all_series_winners_point = 6;
    const conf_first_rounds_second_rounds_conf_finals_winner_point = 4;
    const all_first_rounds_second_rounds_conf_finals_winner_point = 4;

    for (let playerStats of playersStats){
        playerStats.long_term_points = 0;
        playerStats.bonus_points = 0;

        if (playerStats.play_in_predictions.east_7_vs_8 && seasonInfo.play_in.east_7_vs_8.result && playerStats.play_in_predictions.east_7_vs_8 === seasonInfo.play_in.east_7_vs_8.result){
            playerStats.play_in_points += play_in_7_vs_8_result_point;
        }
        if (playerStats.play_in_predictions.east_9_vs_10 && seasonInfo.play_in.east_9_vs_10.result && playerStats.play_in_predictions.east_9_vs_10 === seasonInfo.play_in.east_9_vs_10.result){
            playerStats.play_in_points += play_in_9_vs_10_result_point;
        }
        if (playerStats.play_in_predictions.west_7_vs_8 && seasonInfo.play_in.west_7_vs_8.result && playerStats.play_in_predictions.west_7_vs_8 === seasonInfo.play_in.west_7_vs_8.result){
            playerStats.play_in_points += play_in_7_vs_8_result_point;
        }
        if (playerStats.play_in_predictions.west_9_vs_10 && seasonInfo.play_in.west_9_vs_10.result && playerStats.play_in_predictions.west_9_vs_10 === seasonInfo.play_in.west_9_vs_10.result){
            playerStats.play_in_points += play_in_9_vs_10_result_point;
        }
        if (playerStats.play_in_predictions.east_8_vs_9 && seasonInfo.play_in.east_8_vs_9.result && playerStats.play_in_predictions.east_8_vs_9 === seasonInfo.play_in.east_8_vs_9.result){
            playerStats.play_in_points += play_in_8_vs_9_result_point;
        }
        if (playerStats.play_in_predictions.west_8_vs_9 && seasonInfo.play_in.west_8_vs_9.result && playerStats.play_in_predictions.west_8_vs_9 === seasonInfo.play_in.west_8_vs_9.result){
            playerStats.play_in_points += play_in_8_vs_9_result_point;
        }

        playerStats.total_points += playerStats.play_in_points;

        let firstRoundCorrectEastGuessCount = 0;
        let firstRoundCorrectWestGuessCount = 0;
        if (playerStats.first_round_predictions.east_1_vs_8 && seasonInfo.first_round.east_1_vs_8.result){

            const playerWinner1or2 = playerStats.first_round_predictions.east_1_vs_8.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.east_1_vs_8.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectEastGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.east_1_vs_8.result.split("_")[1]) + parseInt(seasonInfo.first_round.east_1_vs_8.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.east_1_vs_8 === seasonInfo.first_round.east_1_vs_8.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.east_1_vs_8.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.east_1_vs_8.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.east_2_vs_7 && seasonInfo.first_round.east_2_vs_7.result){

            const playerWinner1or2 = playerStats.first_round_predictions.east_2_vs_7.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.east_2_vs_7.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectEastGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.east_2_vs_7.result.split("_")[1]) + parseInt(seasonInfo.first_round.east_2_vs_7.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.east_2_vs_7 === seasonInfo.first_round.east_2_vs_7.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.east_2_vs_7.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.east_2_vs_7.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.east_3_vs_6 && seasonInfo.first_round.east_3_vs_6.result){

            const playerWinner1or2 = playerStats.first_round_predictions.east_3_vs_6.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.east_3_vs_6.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectEastGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.east_3_vs_6.result.split("_")[1]) + parseInt(seasonInfo.first_round.east_3_vs_6.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.east_3_vs_6 === seasonInfo.first_round.east_3_vs_6.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.east_3_vs_6.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.east_3_vs_6.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.east_4_vs_5 && seasonInfo.first_round.east_4_vs_5.result){

            const playerWinner1or2 = playerStats.first_round_predictions.east_4_vs_5.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.east_4_vs_5.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectEastGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.east_4_vs_5.result.split("_")[1]) + parseInt(seasonInfo.first_round.east_4_vs_5.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.east_4_vs_5 === seasonInfo.first_round.east_4_vs_5.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.east_4_vs_5.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.east_4_vs_5.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.west_1_vs_8 && seasonInfo.first_round.west_1_vs_8.result){

            const playerWinner1or2 = playerStats.first_round_predictions.west_1_vs_8.split("_")[1] === "4" ? 1 : 2;

            const result1or2 = seasonInfo.first_round.west_1_vs_8.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.west_1_vs_8.result.split("_")[1]) + parseInt(seasonInfo.first_round.west_1_vs_8.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.west_1_vs_8 === seasonInfo.first_round.west_1_vs_8.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.west_1_vs_8.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.west_1_vs_8.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.west_2_vs_7 && seasonInfo.first_round.west_2_vs_7.result){

            const playerWinner1or2 = playerStats.first_round_predictions.west_2_vs_7.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.west_2_vs_7.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.west_2_vs_7.result.split("_")[1]) + parseInt(seasonInfo.first_round.west_2_vs_7.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.west_2_vs_7 === seasonInfo.first_round.west_2_vs_7.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.west_2_vs_7.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.west_2_vs_7.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.west_3_vs_6 && seasonInfo.first_round.west_3_vs_6.result){

            const playerWinner1or2 = playerStats.first_round_predictions.west_3_vs_6.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.west_3_vs_6.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.west_3_vs_6.result.split("_")[1]) + parseInt(seasonInfo.first_round.west_3_vs_6.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.west_3_vs_6 === seasonInfo.first_round.west_3_vs_6.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.west_3_vs_6.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.west_3_vs_6.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }
        if (playerStats.first_round_predictions.west_4_vs_5 && seasonInfo.first_round.west_4_vs_5.result){

            const playerWinner1or2 = playerStats.first_round_predictions.west_4_vs_5.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.first_round.west_4_vs_5.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.first_round_points += first_round_winner_point;
                firstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.first_round.west_4_vs_5.result.split("_")[1]) + parseInt(seasonInfo.first_round.west_4_vs_5.result.split("_")[2]) === 7){
                    playerStats.first_round_points += first_round_seventh_game_lost_point;
                }
            }

            if (playerStats.first_round_predictions.west_4_vs_5 === seasonInfo.first_round.west_4_vs_5.result){
                playerStats.first_round_points += first_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.first_round.west_4_vs_5.team_1["percentage"]) : parseFloat(seasonStats.statistics.first_round.west_4_vs_5.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.first_round_points += first_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.first_round_points += first_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.first_round_points += first_round_minority_point;
            }
        }

        if (playerStats.first_round_predictions.scorer && seasonInfo.first_round.scorer.result && playerStats.first_round_predictions.scorer === seasonInfo.first_round.scorer.result){
            playerStats.bonus_points += scorer_point;
        }
        if (firstRoundCorrectEastGuessCount === 4) playerStats.bonus_points += first_round_all_conf_series_winners_point;
        if (firstRoundCorrectWestGuessCount === 4) playerStats.bonus_points += first_round_all_conf_series_winners_point;
        if (firstRoundCorrectEastGuessCount === 4 && firstRoundCorrectWestGuessCount === 4) playerStats.bonus_points += first_round_all_series_winners_point;

        playerStats.total_points += playerStats.first_round_points;

        let afterFirstRoundCorrectEastGuessCount = 0;
        let afterFirstRoundCorrectWestGuessCount = 0;
        if (playerStats.second_round_predictions.east_1_8_vs_4_5 && seasonInfo.second_round.east_1_8_vs_4_5.result){

            const playerWinner1or2 = playerStats.second_round_predictions.east_1_8_vs_4_5.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.second_round.east_1_8_vs_4_5.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.second_round_points += second_round_winner_point;
                afterFirstRoundCorrectEastGuessCount++;

            } else{
                if (parseInt(seasonInfo.second_round.east_1_8_vs_4_5.result.split("_")[1]) + parseInt(seasonInfo.second_round.east_1_8_vs_4_5.result.split("_")[2]) === 7){
                    playerStats.second_round_points += second_round_seventh_game_lost_point;
                }
            }

            if (playerStats.second_round_predictions.east_1_8_vs_4_5 === seasonInfo.second_round.east_1_8_vs_4_5.result){
                playerStats.second_round_points += second_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.second_round.east_1_8_vs_4_5.team_1["percentage"]) : parseFloat(seasonStats.statistics.second_round.east_1_8_vs_4_5.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.second_round_points += second_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.second_round_points += second_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.second_round_points += second_round_minority_point;
            }
        }
        if (playerStats.second_round_predictions.east_2_7_vs_3_6 && seasonInfo.second_round.east_2_7_vs_3_6.result){

            const playerWinner1or2 = playerStats.second_round_predictions.east_2_7_vs_3_6.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.second_round.east_2_7_vs_3_6.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.second_round_points += second_round_winner_point;
                afterFirstRoundCorrectEastGuessCount++;
            } else{
                if (parseInt(seasonInfo.second_round.east_2_7_vs_3_6.result.split("_")[1]) + parseInt(seasonInfo.second_round.east_2_7_vs_3_6.result.split("_")[2]) === 7){
                    playerStats.second_round_points += second_round_seventh_game_lost_point;
                }
            }

            if (playerStats.second_round_predictions.east_2_7_vs_3_6 === seasonInfo.second_round.east_2_7_vs_3_6.result){
                playerStats.second_round_points += second_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.second_round.east_2_7_vs_3_6.team_1["percentage"]) : parseFloat(seasonStats.statistics.second_round.east_2_7_vs_3_6.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.second_round_points += second_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.second_round_points += second_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.second_round_points += second_round_minority_point;
            }
        }
        if (playerStats.second_round_predictions.west_1_8_vs_4_5 && seasonInfo.second_round.west_1_8_vs_4_5.result){

            const playerWinner1or2 = playerStats.second_round_predictions.west_1_8_vs_4_5.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.second_round.west_1_8_vs_4_5.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.second_round_points += second_round_winner_point;
                afterFirstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.second_round.west_1_8_vs_4_5.result.split("_")[1]) + parseInt(seasonInfo.second_round.west_1_8_vs_4_5.result.split("_")[2]) === 7){
                    playerStats.second_round_points += second_round_seventh_game_lost_point;
                }
            }

            if (playerStats.second_round_predictions.west_1_8_vs_4_5 === seasonInfo.second_round.west_1_8_vs_4_5.result){
                playerStats.second_round_points += second_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.second_round.west_1_8_vs_4_5.team_1["percentage"]) : parseFloat(seasonStats.statistics.second_round.west_1_8_vs_4_5.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.second_round_points += second_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.second_round_points += second_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.second_round_points += second_round_minority_point;
            }
        }
        if (playerStats.second_round_predictions.west_2_7_vs_3_6 && seasonInfo.second_round.west_2_7_vs_3_6.result){

            const playerWinner1or2 = playerStats.second_round_predictions.west_2_7_vs_3_6.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.second_round.west_2_7_vs_3_6.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.second_round_points += second_round_winner_point;
                afterFirstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.second_round.west_2_7_vs_3_6.result.split("_")[1]) + parseInt(seasonInfo.second_round.west_2_7_vs_3_6.result.split("_")[2]) === 7){
                    playerStats.second_round_points += second_round_seventh_game_lost_point;
                }
            }

            if (playerStats.second_round_predictions.west_2_7_vs_3_6 === seasonInfo.second_round.west_2_7_vs_3_6.result){
                playerStats.second_round_points += second_round_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.second_round.west_2_7_vs_3_6.team_1["percentage"]) : parseFloat(seasonStats.statistics.second_round.west_2_7_vs_3_6.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.second_round_points += second_round_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.second_round_points += second_round_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.second_round_points += second_round_minority_point;
            }
        }

        if (playerStats.second_round_predictions.scorer && seasonInfo.second_round.scorer.result && playerStats.second_round_predictions.scorer === seasonInfo.second_round.scorer.result){
            playerStats.bonus_points += scorer_point;
        }

        playerStats.total_points += playerStats.second_round_points;

        if (playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7 && seasonInfo.conf_finals.east_1_4_5_8_vs_2_3_6_7.result){

            const playerWinner1or2 = playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.conf_finals.east_1_4_5_8_vs_2_3_6_7.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.conf_finals_points += conf_finals_winner_point;
                afterFirstRoundCorrectEastGuessCount++;

            } else{
                if (parseInt(seasonInfo.conf_finals.east_1_4_5_8_vs_2_3_6_7.result.split("_")[1]) + parseInt(seasonInfo.conf_finals.east_1_4_5_8_vs_2_3_6_7.result.split("_")[2]) === 7){
                    playerStats.conf_finals_points += conf_finals_seventh_game_lost_point;
                }
            }

            if (playerStats.conf_finals_predictions.east_1_4_5_8_vs_2_3_6_7 === seasonInfo.conf_finals.east_1_4_5_8_vs_2_3_6_7.result){
                playerStats.conf_finals_points += conf_finals_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_1["percentage"]) : parseFloat(seasonStats.statistics.conf_finals.east_1_4_5_8_vs_2_3_6_7.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.conf_finals_points += conf_finals_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.conf_finals_points += conf_finals_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.conf_finals_points += conf_finals_minority_point;
            }
        }
        if (playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7 && seasonInfo.conf_finals.west_1_4_5_8_vs_2_3_6_7.result){

            const playerWinner1or2 = playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.conf_finals.west_1_4_5_8_vs_2_3_6_7.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.conf_finals_points += conf_finals_winner_point;
                afterFirstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.conf_finals.west_1_4_5_8_vs_2_3_6_7.result.split("_")[1]) + parseInt(seasonInfo.conf_finals.west_1_4_5_8_vs_2_3_6_7.result.split("_")[2]) === 7){
                    playerStats.conf_finals_points += conf_finals_seventh_game_lost_point;
                }
            }

            if (playerStats.conf_finals_predictions.west_1_4_5_8_vs_2_3_6_7 === seasonInfo.conf_finals.west_1_4_5_8_vs_2_3_6_7.result){
                playerStats.conf_finals_points += conf_finals_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_1["percentage"]) : parseFloat(seasonStats.statistics.conf_finals.west_1_4_5_8_vs_2_3_6_7.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.conf_finals_points += conf_finals_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.conf_finals_points += conf_finals_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.conf_finals_points += conf_finals_minority_point;
            }
        }

        if (playerStats.conf_finals_predictions.scorer && seasonInfo.conf_finals.scorer.result && playerStats.conf_finals_predictions.scorer === seasonInfo.conf_finals.scorer.result){
            playerStats.bonus_points += scorer_point;
        }

        playerStats.total_points += playerStats.conf_finals_points;

        if (playerStats.finals_predictions.east_vs_west && seasonInfo.finals.east_vs_west.result){

            const playerWinner1or2 = playerStats.finals_predictions.east_vs_west.split("_")[1] === "4" ? 1 : 2;
            const result1or2 = seasonInfo.finals.east_vs_west.result.split("_")[1] === "4" ? 1 : 2;

            if (playerWinner1or2 === result1or2){
                playerStats.finals_points += finals_winner_point;
                afterFirstRoundCorrectEastGuessCount++;
                afterFirstRoundCorrectWestGuessCount++;
            } else{
                if (parseInt(seasonInfo.finals.east_vs_west.result.split("_")[1]) + parseInt(seasonInfo.finals.east_vs_west.result.split("_")[2]) === 7){
                    playerStats.finals_points += finals_seventh_game_lost_point;
                }
            }

            if (playerStats.finals_predictions.east_vs_west === seasonInfo.finals.east_vs_west.result){
                playerStats.finals_points += finals_result_point;
            }

            const playerSelectionPercentage = playerWinner1or2 === 1 ? parseFloat(seasonStats.statistics.finals.east_vs_west.team_1["percentage"]) : parseFloat(seasonStats.statistics.finals.east_vs_west.team_2["percentage"])

            if (playerSelectionPercentage < 3) {
                playerStats.finals_points += finals_only_minority_point;
            } else if (playerSelectionPercentage < 25) {
                playerStats.finals_points += finals_below_25_minority_point;
            } else if (playerSelectionPercentage < 50){
                playerStats.finals_points += finals_minority_point;
            }
        }

        if (playerStats.finals_predictions.scorer && seasonInfo.finals.scorer.result && playerStats.finals_predictions.scorer === seasonInfo.finals.scorer.result){
            playerStats.bonus_points += scorer_point;
        }

        playerStats.total_points += playerStats.finals_points;

        if (afterFirstRoundCorrectEastGuessCount === 4) playerStats.bonus_points += conf_first_rounds_second_rounds_conf_finals_winner_point;
        if (afterFirstRoundCorrectWestGuessCount === 4) playerStats.bonus_points += conf_first_rounds_second_rounds_conf_finals_winner_point;
        if (afterFirstRoundCorrectEastGuessCount + afterFirstRoundCorrectEastGuessCount === 8) playerStats.bonus_points += all_first_rounds_second_rounds_conf_finals_winner_point;

        playerStats.total_points += playerStats.bonus_points;

        if (playerStats.long_term_predictions){

            if (playerStats.long_term_predictions.east_champion && seasonInfo.long_term.east_champion.result && playerStats.long_term_predictions.east_champion === seasonInfo.long_term.east_champion.result){
                playerStats.long_term_points += conf_champion_point;
            }

            if (playerStats.long_term_predictions.west_champion && seasonInfo.long_term.west_champion.result && playerStats.long_term_predictions.west_champion === seasonInfo.long_term.west_champion.result){
                playerStats.long_term_points += conf_champion_point;
            }

            if (playerStats.long_term_predictions.champion && seasonInfo.long_term.champion.result && playerStats.long_term_predictions.champion === seasonInfo.long_term.champion.result){
                playerStats.long_term_points += champion_point;
            }

            if (playerStats.long_term_predictions.finals_mvp && seasonInfo.long_term.finals_mvp.result && playerStats.long_term_predictions.finals_mvp === seasonInfo.long_term.finals_mvp.result){
                playerStats.long_term_points += finals_mvp_point;
            }
        }

        playerStats.total_points += playerStats.long_term_points;
    }
}








