import {SET_LOCATION_HISTORY_ACTION, SET_PREV_LOCATION_ACTION} from "../constants/actionTypes";
 
const initialState ={};

const commonReducer = (state = initialState, action) =>{

    switch(action.type){

        case SET_LOCATION_HISTORY_ACTION:
            return{
                ...state,
                locationHistory: action.payload,
            }

        case SET_PREV_LOCATION_ACTION:
            return{
                ...state,
                prevLocation: action.payload,
            }

        default:
            return state
    }
}

export default commonReducer;
