import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import ButtonComponent from "../button/buttonComponent";
import {registerInit} from "../../services/userService";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase-config";
import InputErrorTextComponent from "../input/inputErrorTextComponent";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

const validateName = (name) => {
    return !(name === undefined || name === "" || name.length < 4);
}

const validateTelNumber = (number) => {
    return !(number === undefined || number === "");
}

function RegisterFormComponent(props) {
    const dispatch = useDispatch();

    const [errorText, setErrorText] = useState(null);
    const [userName, setUserName ] = useState("");
    const [telNumber, setTelNumber] = useState("");
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const signOutFunc = () => {
        signOut(auth).then(_ => {
            //console.log("Sign-out successful.")
        }).catch((err) => {
            console.log("signOut error", err)
        });
    }

    const submit = () => {
        setButtonsDisabled(true)

        if (!validateName(userName)){
            setErrorText("Kulllanıcı adınız en az 4 karakter olmalı.");
            setButtonsDisabled(false)
            return;
        }

        if (!validateTelNumber(telNumber)){
            setErrorText("Telefon numarasını kontrol ediniz.");
            setButtonsDisabled(false)
            return;
        }

        setErrorText(null);

        const user = {
            id: props.user.id,
            is_admin: false,
            is_registered: true,
            phone_number: telNumber,
            name: userName,
            email: props.user.email,
            player_of: [],
            commissioner_of: [],
            owner_of: []
        }

        registerInit(user, dispatch).then(_ => {
            setButtonsDisabled(false)
        }).catch(err => {
            console.log("setUser err:", err)
        })
    }

    return(
        <div className="form-container">
            <h2>Üye Bilgilerinizi Belirleyiniz</h2>
            <form onSubmit={(e)=>e.preventDefault()}>
                <div className="input-item">
                    <label>Kullanıcı Adı</label>
                    <input type="text" value={userName} onChange={(e) => {
                        const regex = /^[a-zA-Z0-9]+$/;
                        if (e.target.value === "" || regex.test(e.target.value)) {
                            setUserName(e.target.value)
                        }}}
                        onKeyDown={(e)=>{if (e.key === "Enter" && !buttonsDisabled) submit()}}
                    />
                </div>

                <div className="input-item">
                    <PhoneInput
                        defaultCountry="TR"
                        value={telNumber}
                        onChange={setTelNumber}
                        placeholder={"Telefon Numaranızı Başında '0' Olmadan Giriniz."}
                        onKeyDown={(e)=>{if(e.keyCode === 13 && !buttonsDisabled) submit()}}
                    />
                </div>

                <ButtonComponent type="submit" text="Kaydet" isDisabled={buttonsDisabled} onClick={submit}/>

                <InputErrorTextComponent errorText={errorText}/>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

export default connect(mapStateToProps) (RegisterFormComponent);