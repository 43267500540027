import React, {useEffect} from "react";

function NbaPlayoffSeasonStatisticsLbComponent(props) {
    const lbContainer = React.createRef();
    const lbWrapper = React.createRef();

    useEffect(() => {
        lbWrapper.current && lbWrapper.current.addEventListener("click", windowClicked);

        return () => {
            lbWrapper.current && lbWrapper.current.removeEventListener("click", windowClicked);
        }
    });

    const windowClicked = (e) => {
        if (lbContainer.current && !lbContainer.current.contains(e.target)) {
            props.setNullFunc(null)
        }
    }

    return (
        <div className="lb-root">
            <div className="lb-wrapper bg-shadow" ref={lbWrapper}>
                <div className="lb-container statistics" ref={lbContainer}>
                    <div className="lb-head-block">
                        <span>{props.fieldName}</span>
                        <div className="close-icon" onClick={()=>props.setNullFunc(null)}/>
                    </div>

                    <div className="stats-block">
                        {
                            props.statistics && Object.keys(props.statistics).map((obj, index) =>
                                <div className="stat-item" key={index}>
                                    <div className="stat">
                                        <span>Seçim: </span> {props.statistics[obj].name}
                                    </div>
                                    <div className="stat">
                                        <span>Seçim sayısı: </span>{props.statistics[obj].amount}
                                    </div>
                                    <div className="stat">
                                        <span>Seçim oranı: </span> %{props.statistics[obj].percentage}
                                    </div>
                                </div>
                            )
                        }
                    </div>


                    {
                        props.playersPredictions &&
                        <div className="table-container statistics">
                            <div className="table-head-block">
                                <div className="table-item">
                                    <div className="row-item no"></div>
                                    <div className="row-item player">Üye Adı</div>
                                    <div className="row-item prediction">Seçim</div>
                                </div>
                            </div>
                            <div className="table-block">
                                {
                                    props.playersPredictions.sort( ( a, b ) => {return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0)} ).map((obj, index) =>
                                        <div className="table-item" key={index}>
                                            <div className="row-item no">{index + 1}.</div>
                                            <div className="row-item player">{obj.name}</div>
                                            <div className="row-item prediction">{obj.prediction}</div>
                                        </div>
                                )}
                            </div>
                        </div>
                    }

                    {
                        props.allPlayersStats &&
                        <div className="table-container statistics">
                            <div className="table-head-block">
                                <div className="table-item">
                                    <div className="row-item no"></div>
                                    <div className="row-item player">Üye Adı</div>
                                    <div className="row-item points">Toplam Puan</div>
                                </div>
                            </div>
                            <div className="table-block">
                                {
                                    props.allPlayersStats.map((playerStats, index) =>
                                        <div className="table-item" key={index}>
                                            <div className="row-item no">{index + 1}.</div>
                                            <div className="row-item player">
                                                {playerStats.player.name}
                                                <div className="player-points-container">
                                                    <h4>{playerStats.player.name}</h4>
                                                    <p><span>Play-in: </span> {!!playerStats.play_in_points ? playerStats.play_in_points : "0"}</p>
                                                    <p><span>İlk Tur: </span> {!!playerStats.first_round_points ? playerStats.first_round_points : "0"}</p>
                                                    <p><span>İkinci Tur: </span> {!!playerStats.second_round_points ? playerStats.second_round_points : "0"}</p>
                                                    <p><span>Konf. Finalleri: </span> {!!playerStats.conf_finals_points ? playerStats.conf_finals_points : "0"}</p>
                                                    <p><span>Final: </span> {!!playerStats.finals_points ? playerStats.finals_points : "0"}</p>
                                                    <p><span>Uzun Vade: </span> {!!playerStats.long_term_points ? playerStats.long_term_points : "0"}</p>
                                                    <p><span>Bonuslar: </span> {!!playerStats.bonus_points ? playerStats.bonus_points : "0"}</p>
                                                    <hr/>
                                                    <p><span>Toplam Puan: </span> {!!playerStats.total_points ? playerStats.total_points : "0"}</p>
                                                </div>
                                            </div>
                                            <div className="row-item points">{playerStats.total_points}</div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default NbaPlayoffSeasonStatisticsLbComponent;