import React, {useEffect, useState} from "react";
import NbaPlayoffSeasonStatisticsLbComponent from "../../form/nbaPlayoff/nbaPlayoffSeasonStatisticsLbComponent";
import Select from "react-select";
import {customStyles} from "../../../helper";
import {connect} from "react-redux";

function NbaPlayoffInputItemComponent(props) {
    const [resultType, setResultType] = useState("");
    const [resultLabel, setResultLabel] = useState(null);

    useEffect(() => {
        if (!(props.inputValue && props.inputValue.value && props.info && props.info.result)){
            return;
        }

        if (props.inputValue.value === props.info.result){
            setResultType("win");
            return;
        }

        if (!(props.info.result.split("_") && props.inputValue.value.split("_"))){
            setResultLabel(props.info.result);
            setResultType("lost");
            return;
        }

        if (props.info.result.split("_")[0] === "result"){
            const result1or2 = props.info.result.split("_")[1] === "4" ? 1 : 2;
            const input1or2 = props.inputValue.value.split("_")[1] === "4" ? 1 : 2;

            setResultLabel(props.info.result.split("_")[1] + "-" + props.info.result.split("_")[2]);
            setResultType(input1or2 === result1or2 ? "less-win" : "lost");
            return;
        }

        if (props.info.result.split("_")[0] === "team"){
            setResultLabel(props.info[props.info.result]);
            setResultType("lost");
        }

    }, [props.inputValue, props.info]);

    return (
        <div className="input-item">
            <label>{props.labelText}</label>

            {
                (props.user.is_admin || !props.is_active) &&
                <div className="predictions-stats-block">
                    <p>
                        {
                            props.stats &&
                            <>
                                <span onClick={() => props.setStatisticsLb(props.inputName)}>İstatistikler</span> |
                            </>
                        }
                        <span onClick={() => props.setPredictionsLb(props.inputName)}> Tahmin Listesi</span>
                    </p>

                    {
                        props.statisticsLb === props.inputName &&
                        <NbaPlayoffSeasonStatisticsLbComponent
                            fieldName={props.labelText}
                            setNullFunc={props.setStatisticsLb}
                            statistics={props.stats}
                        />
                    }

                    {
                        props.predictionsLb === props.inputName &&
                        <NbaPlayoffSeasonStatisticsLbComponent
                            fieldName={props.labelText}
                            setNullFunc={props.setPredictionsLb}
                            playersPredictions={props.predictions}
                        />
                    }
                </div>
            }

            {
                props.is_active ?
                    <Select
                        options={props.inputOptions}
                        value={props.inputValue}
                        placeholder={null}
                        onChange={(value) => props.setInputValue(value)}
                        styles={customStyles}
                        isDisabled={props.buttonsDisabled || !props.is_active}
                        classNamePrefix="react-select"
                    />

                    :

                    <>
                        {
                            props.inputValue &&

                            <div className="result-text">
                                Seçimin: <span className={resultType}>{props.inputValue.label}</span>
                            </div>
                        }

                        {
                            resultLabel &&
                            <div className="result-text">
                                Sonuç: <span className="result">{resultLabel}</span>
                            </div>
                        }
                    </>
            }


        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
})

export default connect(mapStateToProps)(NbaPlayoffInputItemComponent);