import React, {useEffect, useState} from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";
import {connect, useDispatch} from "react-redux";
import LoginPage from "../pages/loginPage";
import HeaderComponent from "./layout/headerComponent";
import RegisterPage from "../pages/registerPage";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {getUser} from "../services/apiCalls";
import {getUserAction} from "../services/userService";
import LoaderComponent from "./layout/loaderComponent";
import LandingPage from "../pages/landingPage";
import ProfilePage from "../pages/profilePage";
import LeaguePage from "../pages/leaguePage";
import {SEASON_PAGE, SEASON_PANEL_PAGE} from "../constants/routePaths";
import SeasonPage from "../pages/seasonPage";
import SeasonPanelPage from "../pages/seasonPanelPage";
import {locationActionInit} from "../services/commonService";
import {auth} from "../firebase-config";

function RoutesComponent(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation()

    useEffect(() => {
        locationActionInit(dispatch, location, structuredClone(props.locationHistory));
    }, [location]);

    useEffect(() => {
        const authListener = onAuthStateChanged(getAuth(), async (user) => {
            if (user){
                const docSnap = await getUser(user.uid);
                if (docSnap.exists()) {
                    dispatch(getUserAction(docSnap.data()));
                } else {
                    console.log("user logged but user with uid u not found!!", user.uid)
                }
                setIsLoading(false);
            } else {
                dispatch(getUserAction());
                setIsLoading(false);
            }
        });

        return () => {
            authListener();
        };
    }, []);

    return (
        <>

            {
                isLoading ?

                    <LoaderComponent/>

                    :

                    <>
                        {
                            !props.user ?

                                <Routes>
                                    <Route path={"*"} element={<Navigate replace to={ROUTES.LOGIN_PAGE} />} />
                                    <Route path={ROUTES.LOGIN_PAGE} element={<LoginPage />} />
                                </Routes>

                                :

                                props.user.is_registered === false ?

                                    <Routes>
                                        <Route path={"*"} element={<Navigate replace to={ROUTES.REGISTER_PAGE} />} />
                                        <Route path={ROUTES.REGISTER_PAGE} element={<RegisterPage />} />
                                    </Routes>

                                    :

                                    <>
                                        <HeaderComponent/>

                                        <Routes>
                                            {/*<Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />*/}
                                            {/*<Route path={ROUTES.PROFILE_PAGE} element={<ProfilePage />} />*/}
                                            {/*<Route path={ROUTES.LEAGUE_PAGE} element={<LeaguePage />} />*/}
                                            <Route path={ROUTES.SEASON_PAGE} element={<SeasonPage />} />
                                            <Route path={ROUTES.SEASON_PANEL_PAGE} element={<SeasonPanelPage />} />
                                            {/*<Route path={"*"} element={<Navigate replace to={ROUTES.SEASON_PAGE} />} />*/}
                                            <Route path={"*"} element={<Navigate replace to={ROUTES.LANDING_PAGE} />} />
                                        </Routes>
                                    </>
                        }
                    </>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    locationHistory: state.commonReducer.locationHistory ? state.commonReducer.locationHistory : [],
    prevLocation: state.commonReducer.prevLocation,
})

export default connect(mapStateToProps) (RoutesComponent);