import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyApUahgPTWW-MF45Ce-TcehgDDRGNxzAIg",
    authDomain: "fantezi-ligim.firebaseapp.com",
    projectId: "fantezi-ligim",
    storageBucket: "fantezi-ligim.appspot.com",
    messagingSenderId: "276408338508",
    appId: "1:276408338508:web:69dafe36539898fcd34ce9",
    measurementId: "G-EYZG36RMTH"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;