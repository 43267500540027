import React from "react";
import LoginWithMailFormComponent from "../components/form/loginWithMailFormComponent";

function LoginPage() {

    return (
        <div className="login-page-root">
            <div className="login-page-wrapper">
                <LoginWithMailFormComponent/>
            </div>
        </div>
    );
}

export default LoginPage;