import React, {useEffect, useState} from "react";
import {signOut } from "firebase/auth";
import {auth} from "../../firebase-config";
import {Link} from "react-router-dom";
import {LANDING_PAGE, PROFILE_PAGE} from "../../constants/routePaths";
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";

function HeaderComponent(props) {
    const [isProfileMenuOpen, setProfileMenu] = useState(false);
    const openProfileRef = React.createRef();

    const signOutFunc = () => {
        signOut(auth).then(_ => {
            //console.log("Sign-out successful.")
        }).catch((err) => {
            console.log("signOut error", err)
        });
    }

    const toggleProfileMenu = () => {
        setProfileMenu(!isProfileMenuOpen)
    }

    useEffect(() => {
        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        }
    });

    const windowClicked = (e) => {
        if (openProfileRef.current && !openProfileRef.current.contains(e.target) && isProfileMenuOpen) {
            setProfileMenu(false)
        }
    }

    return (
        <div className={"header-root"}>
            <div className="header-wrapper">
                <div className="header-container">
                    <div className="header-block left">
                        {/*<Link to={LANDING_PAGE} className="nav-item"></Link>*/}
                    </div>

                    <Link to={LANDING_PAGE} className="header-img-item">
                        <img src={`${ASSETS}/img/header-img.jpg`} alt="basketbol"/>
                    </Link>

                    <div className="header-block right">
                        <Link to={LANDING_PAGE} className="nav-item">

                        </Link>

                        <div className="profile-block" ref={openProfileRef} onClick={()=>{toggleProfileMenu()}}>
                            <div className="nav-item">
                                <span>{props.user.name}</span>
                                <div className={`chevron-icon ${isProfileMenuOpen ? "open" : ""}`} />
                            </div>
                            {
                                isProfileMenuOpen &&
                                <div className="open-profile-menu">
                                    {/*<Link to={`${PROFILE_PAGE_WITHOUT_PARAM}/${props.member.memberName}`} className="nav-item">
                                        <span className="profile">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                        </svg>
                                            Profil
                                        </span>
                                    </Link>
                                    {
                                        props.member.isAdmin &&
                                        <Link to={ADMIN_PAGE} className="nav-item">
                                            <span className="admin">
                                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF">
                                                    <rect fill="none" height={20} width={20} />
                                                    <path d="M12,1L3,5v6c0,5.55,3.84,10.74,9,12c5.16-1.26,9-6.45,9-12V5L12,1z M14.5,12.59l0.9,3.88L12,14.42l-3.4,2.05l0.9-3.87 l-3-2.59l3.96-0.34L12,6.02l1.54,3.64L17.5,10L14.5,12.59z" />
                                                </svg>
                                                Admin
                                            </span>
                                        </Link>
                                    }*/}
                                    <div className="nav-item" onClick={()=>{signOutFunc();}}>
                                        <span className="log-out">
                                            <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.1665 3.83333L11.9915 5.00833L14.1415 7.16667H5.6665V8.83333H14.1415L11.9915 10.9833L13.1665 12.1667L17.3332 8L13.1665 3.83333ZM2.33317 2.16667H8.99984V0.5H2.33317C1.4165 0.5 0.666504 1.25 0.666504 2.16667V13.8333C0.666504 14.75 1.4165 15.5 2.33317 15.5H8.99984V13.8333H2.33317V2.16667Z" fill="#FFFFFF" />
                                            </svg>
                                            Çıkış
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
})

export default connect(mapStateToProps) (HeaderComponent);