import {GET_LEAGUE_ACTION} from "../constants/actionTypes";
 
const initialState ={};

const leagueReducer = (state = initialState, action) =>{

    switch(action.type){

        case GET_LEAGUE_ACTION:
            return{
                ...state,
                league: action.payload,
            }

        default:
            return state
    }
}

export default leagueReducer;
