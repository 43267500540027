import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {isTimeNotUp} from "../../../helper";
import ButtonComponent from "../../button/buttonComponent";
import {updateSeasonPlayerStatsInit} from "../../../services/seasonService";
import ConfirmationTxtLbComponent from "../../layout/confirmationTxtLbComponent";
import NbaPlayoffInputItemComponent from "../../input/nbaPlayoff/nbaPlayoffInputItemComponent";
import {nbaPlayoffSeriesInputOptions} from "../../../constants/variables";

function NbaFirstRoundPredictionsFormComponent(props) {
    const dispatch = useDispatch();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [confirmationTxt, setConfirmationTxt] = useState(null);

    const [predictionsLb, setPredictionsLb] = useState(null);
    const [statisticsLb, setStatisticsLb] = useState(null);

    const [east_1_vs_8_prediction, set_east_1_vs_8_prediction] = useState(null);
    const [east_2_vs_7_prediction, set_east_2_vs_7_prediction] = useState(null);
    const [east_3_vs_6_prediction, set_east_3_vs_6_prediction] = useState(null);
    const [east_4_vs_5_prediction, set_east_4_vs_5_prediction] = useState(null);
    const [west_1_vs_8_prediction, set_west_1_vs_8_prediction] = useState(null);
    const [west_2_vs_7_prediction, set_west_2_vs_7_prediction] = useState(null);
    const [west_3_vs_6_prediction, set_west_3_vs_6_prediction] = useState(null);
    const [west_4_vs_5_prediction, set_west_4_vs_5_prediction] = useState(null);
    const [scorer_prediction, set_scorer_prediction] = useState(null);

    const [is_east_1_vs_8_active, set_east_1_vs_8_active] = useState(false);
    const [is_east_2_vs_7_active, set_east_2_vs_7_active] = useState(false);
    const [is_east_3_vs_6_active, set_east_3_vs_6_active] = useState(false);
    const [is_east_4_vs_5_active, set_east_4_vs_5_active] = useState(false);
    const [is_west_1_vs_8_active, set_west_1_vs_8_active] = useState(false);
    const [is_west_2_vs_7_active, set_west_2_vs_7_active] = useState(false);
    const [is_west_3_vs_6_active, set_west_3_vs_6_active] = useState(false);
    const [is_west_4_vs_5_active, set_west_4_vs_5_active] = useState(false);
    const [is_scorer_active, set_scorer_active] = useState(false);

    useEffect(() => {
        if (props.playerStats) {

            if (props.playerStats.first_round_predictions.east_1_vs_8) {
                const value = props.playerStats.first_round_predictions.east_1_vs_8;
                set_east_1_vs_8_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.east_2_vs_7) {
                const value = props.playerStats.first_round_predictions.east_2_vs_7;
                set_east_2_vs_7_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.east_3_vs_6) {
                const value = props.playerStats.first_round_predictions.east_3_vs_6;
                set_east_3_vs_6_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.east_4_vs_5) {
                const value = props.playerStats.first_round_predictions.east_4_vs_5;
                set_east_4_vs_5_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.west_1_vs_8) {
                const value = props.playerStats.first_round_predictions.west_1_vs_8;
                set_west_1_vs_8_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.west_2_vs_7) {
                const value = props.playerStats.first_round_predictions.west_2_vs_7;
                set_west_2_vs_7_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.west_3_vs_6) {
                const value = props.playerStats.first_round_predictions.west_3_vs_6;
                set_west_3_vs_6_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.west_4_vs_5) {
                const value = props.playerStats.first_round_predictions.west_4_vs_5;
                set_west_4_vs_5_prediction({
                    value: value,
                    label: value.split("_")[1] + "-" + value.split("_")[2],
                })
            }

            if (props.playerStats.first_round_predictions.scorer) {
                set_scorer_prediction({
                    value: props.playerStats.first_round_predictions.scorer,
                    label: props.playerStats.first_round_predictions.scorer,
                })
            }
        }
    }, [props.playerStats]);

    useEffect(() => {
        setStartTimeActives();
    }, [props.season.info]);

    useEffect(() => {
        setStartTimeActives();
    }, []);

    const setStartTimeActives = () => {
        if (props.season.info) {
            props.season.info.first_round.east_1_vs_8.start_time && set_east_1_vs_8_active(isTimeNotUp(props.season.info.first_round.east_1_vs_8.start_time))
            props.season.info.first_round.east_2_vs_7.start_time && set_east_2_vs_7_active(isTimeNotUp(props.season.info.first_round.east_2_vs_7.start_time))
            props.season.info.first_round.east_3_vs_6.start_time && set_east_3_vs_6_active(isTimeNotUp(props.season.info.first_round.east_3_vs_6.start_time))
            props.season.info.first_round.east_4_vs_5.start_time && set_east_4_vs_5_active(isTimeNotUp(props.season.info.first_round.east_4_vs_5.start_time))
            props.season.info.first_round.west_1_vs_8.start_time && set_west_1_vs_8_active(isTimeNotUp(props.season.info.first_round.west_1_vs_8.start_time))
            props.season.info.first_round.west_2_vs_7.start_time && set_west_2_vs_7_active(isTimeNotUp(props.season.info.first_round.west_2_vs_7.start_time))
            props.season.info.first_round.west_3_vs_6.start_time && set_west_3_vs_6_active(isTimeNotUp(props.season.info.first_round.west_3_vs_6.start_time))
            props.season.info.first_round.west_4_vs_5.start_time && set_west_4_vs_5_active(isTimeNotUp(props.season.info.first_round.west_4_vs_5.start_time))
            props.season.info.first_round.scorer.start_time && set_scorer_active(isTimeNotUp(props.season.info.first_round.scorer.start_time))
        }
    }

    const submitPredictions = () => {
        setButtonsDisabled(true)

        const newPlayerStats = structuredClone(props.playerStats);

        newPlayerStats.first_round_predictions = {
            east_1_vs_8: east_1_vs_8_prediction ? east_1_vs_8_prediction.value : null,
            east_2_vs_7: east_2_vs_7_prediction ? east_2_vs_7_prediction.value : null,
            east_3_vs_6: east_3_vs_6_prediction ? east_3_vs_6_prediction.value : null,
            east_4_vs_5: east_4_vs_5_prediction ? east_4_vs_5_prediction.value : null,
            west_1_vs_8: west_1_vs_8_prediction ? west_1_vs_8_prediction.value : null,
            west_2_vs_7: west_2_vs_7_prediction ? west_2_vs_7_prediction.value : null,
            west_3_vs_6: west_3_vs_6_prediction ? west_3_vs_6_prediction.value : null,
            west_4_vs_5: west_4_vs_5_prediction ? west_4_vs_5_prediction.value : null,
            scorer: scorer_prediction ? scorer_prediction.value : null,
        };

        updateSeasonPlayerStatsInit(props.season.type, newPlayerStats, dispatch).then(_ => {
            setButtonsDisabled(false);
            setConfirmationTxt("İlk tur tahminlerin kaydedildi.")
        });
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>

            {
                props.season.info.first_round.east_1_vs_8.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_1_vs_8_active}
                    inputValue={east_1_vs_8_prediction}
                    setInputValue={set_east_1_vs_8_prediction}
                    inputName={"east_1_vs_8"}
                    labelText={`${props.season.info.first_round.east_1_vs_8.team_1} (Doğu 1)
                        vs. ${props.season.info.first_round.east_1_vs_8.team_2} (Doğu 8)`}
                    stats={props.seasonStats.statistics.first_round.east_1_vs_8}
                    predictions={props.seasonStats.playersPredictions.first_round.east_1_vs_8}
                    info={props.season.info.first_round.east_1_vs_8}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.east_2_vs_7.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_2_vs_7_active}
                    inputValue={east_2_vs_7_prediction}
                    setInputValue={set_east_2_vs_7_prediction}
                    inputName={"east_2_vs_7"}
                    labelText={`${props.season.info.first_round.east_2_vs_7.team_1} (Doğu 2)
                        vs. ${props.season.info.first_round.east_2_vs_7.team_2} (Doğu 7)`}
                    stats={props.seasonStats.statistics.first_round.east_2_vs_7}
                    predictions={props.seasonStats.playersPredictions.first_round.east_2_vs_7}
                    info={props.season.info.first_round.east_2_vs_7}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.east_3_vs_6.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_3_vs_6_active}
                    inputValue={east_3_vs_6_prediction}
                    setInputValue={set_east_3_vs_6_prediction}
                    inputName={"east_3_vs_6"}
                    labelText={`${props.season.info.first_round.east_3_vs_6.team_1} (Doğu 3)
                        vs. ${props.season.info.first_round.east_3_vs_6.team_2} (Doğu 6)`}
                    stats={props.seasonStats.statistics.first_round.east_3_vs_6}
                    predictions={props.seasonStats.playersPredictions.first_round.east_3_vs_6}
                    info={props.season.info.first_round.east_3_vs_6}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.east_4_vs_5.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_east_4_vs_5_active}
                    inputValue={east_4_vs_5_prediction}
                    setInputValue={set_east_4_vs_5_prediction}
                    inputName={"east_4_vs_5"}
                    labelText={`${props.season.info.first_round.east_4_vs_5.team_1} (Doğu 4)
                        vs. ${props.season.info.first_round.east_4_vs_5.team_2} (Doğu 5)`}
                    stats={props.seasonStats.statistics.first_round.east_4_vs_5}
                    predictions={props.seasonStats.playersPredictions.first_round.east_4_vs_5}
                    info={props.season.info.first_round.east_4_vs_5}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.west_1_vs_8.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_1_vs_8_active}
                    inputValue={west_1_vs_8_prediction}
                    setInputValue={set_west_1_vs_8_prediction}
                    inputName={"west_1_vs_8"}
                    labelText={`${props.season.info.first_round.west_1_vs_8.team_1} (Batı 1)
                        vs. ${props.season.info.first_round.west_1_vs_8.team_2} (Batı 8)`}
                    stats={props.seasonStats.statistics.first_round.west_1_vs_8}
                    predictions={props.seasonStats.playersPredictions.first_round.west_1_vs_8}
                    info={props.season.info.first_round.west_1_vs_8}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.west_2_vs_7.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_2_vs_7_active}
                    inputValue={west_2_vs_7_prediction}
                    setInputValue={set_west_2_vs_7_prediction}
                    inputName={"west_2_vs_7"}
                    labelText={`${props.season.info.first_round.west_2_vs_7.team_1} (Batı 2)
                        vs. ${props.season.info.first_round.west_2_vs_7.team_2} (Batı 7)`}
                    stats={props.seasonStats.statistics.first_round.west_2_vs_7}
                    predictions={props.seasonStats.playersPredictions.first_round.west_2_vs_7}
                    info={props.season.info.first_round.west_2_vs_7}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.west_3_vs_6.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_3_vs_6_active}
                    inputValue={west_3_vs_6_prediction}
                    setInputValue={set_west_3_vs_6_prediction}
                    inputName={"west_3_vs_6"}
                    labelText={`${props.season.info.first_round.west_3_vs_6.team_1} (Batı 3)
                        vs. ${props.season.info.first_round.west_3_vs_6.team_2} (Batı 6)`}
                    stats={props.seasonStats.statistics.first_round.west_3_vs_6}
                    predictions={props.seasonStats.playersPredictions.first_round.west_3_vs_6}
                    info={props.season.info.first_round.west_3_vs_6}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.west_4_vs_5.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_west_4_vs_5_active}
                    inputValue={west_4_vs_5_prediction}
                    setInputValue={set_west_4_vs_5_prediction}
                    inputName={"west_4_vs_5"}
                    labelText={`${props.season.info.first_round.west_4_vs_5.team_1} (Batı 4)
                        vs. ${props.season.info.first_round.west_4_vs_5.team_2} (Batı 5)`}
                    stats={props.seasonStats.statistics.first_round.west_4_vs_5}
                    predictions={props.seasonStats.playersPredictions.first_round.west_4_vs_5}
                    info={props.season.info.first_round.west_4_vs_5}
                    inputOptions={nbaPlayoffSeriesInputOptions}
                    setStatisticsLb={setStatisticsLb}
                    statisticsLb={statisticsLb}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.season.info.first_round.scorer.start_time && props.seasonStats &&
                <NbaPlayoffInputItemComponent
                    is_active={is_scorer_active}
                    inputValue={scorer_prediction}
                    setInputValue={set_scorer_prediction}
                    inputName={"scorer"}
                    labelText={`İlk Turun En Skorer Oyuncusu`}
                    predictions={props.seasonStats.playersPredictions.first_round.scorer}
                    info={props.season.info.first_round.scorer}
                    inputOptions={props.season.info.first_round.scorer.list.sort().map(x => ({value: x, label:x}))}
                    setPredictionsLb={setPredictionsLb}
                    predictionsLb={predictionsLb}
                    buttonsDisabled={buttonsDisabled}
                />
            }

            {
                props.playerStats && (
                    is_east_1_vs_8_active ||
                    is_east_2_vs_7_active ||
                    is_east_3_vs_6_active ||
                    is_east_4_vs_5_active ||
                    is_west_1_vs_8_active ||
                    is_west_2_vs_7_active ||
                    is_west_3_vs_6_active ||
                    is_west_4_vs_5_active ||
                        is_scorer_active
                ) &&

                <ButtonComponent type="submit" text={"Kaydet"} onClick={submitPredictions}
                                 isDisabled={buttonsDisabled}/>
            }

            {
                confirmationTxt &&
                <ConfirmationTxtLbComponent text={confirmationTxt} setConfirmationTxt={setConfirmationTxt}/>
            }

        </form>
    );
}

const mapStateToProps = (state) => ({
    season: state.seasonReducer.season,
    playerStats: state.seasonReducer.playerStats,
    allPlayersStats: state.seasonReducer.allPlayersStats,
    seasonStats: state.seasonReducer.seasonStats,
})

export default connect(mapStateToProps)(NbaFirstRoundPredictionsFormComponent);